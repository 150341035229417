import { Fragment, ReactElement, useState } from "react";
import styled from "styled-components";
import { SupplierPropsList, SupplierCont, PropItem, MenuOptionsList, Option } from "./styles";
import { ImageItem } from "../forms/share/imageView";
import { Divider, Icon } from "../../../../components/icons/styles";
import { More } from "../../../../components/icons";
import { queryOptions } from "../../../../types/defaults";
import { showCashBookModal, showListMenu, tableState } from "../../../../globals";
import { DELETE_SUPPLIER } from "../../../../graphql/mutations/supplier.mutation";
import { GET_SUPPLIERS } from "../../../../graphql/queries/supplier.query";
import { useMutation, useReactiveVar } from "@apollo/client";




export function Supplier(props: any): ReactElement {

    // const [menuOpened, reSetMenu] = useState(false);

    const { supplier } = props;

    // const { group, query } = useReactiveVar(expenseCriteria)
    const menuId = useReactiveVar(showListMenu)
    // const togleMenu = () => {
    //     reSetMenu(!menuOpened)
    // }
    const closeMenu = () => {
        showListMenu('')
    }

    let [deleteSupplier, { error, loading }] = useMutation(DELETE_SUPPLIER, {
        update: (cache, { data }) => {
            const existingSuppliers: any = cache.readQuery({
                query: GET_SUPPLIERS,
                variables: {
                    ...queryOptions()
                }
            })
            let newSupplier = existingSuppliers.suppliers.map((supplierGroup: any) => {
                return (supplierGroup.records.some((supplierItem: any) => supplierItem._id === data.deleteSupplier._id)) ?
                    ({
                        ...supplierGroup,
                        records: supplierGroup.records.filter((supplier: any) => supplier._id !== data.deleteSupplier._id)
                    })
                    :
                    supplierGroup
            })
            cache.writeQuery({
                query: GET_SUPPLIERS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    suppliers: newSupplier.filter((supplier: any) => supplier.records.length)
                }
            });
        }
    })

    if (error)
        console.log({ error });

    const deleteItem = (e: any, id: string ) => {
        e.stopPropagation();
        closeMenu()
        deleteSupplier({
            variables: {
                id
            },
        })
    }

    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: supplier
        })
        showCashBookModal(true)
        showListMenu('')
    }

    return (
        <SupplierCont>
            <ImageItem name={supplier.name} />
            <SupplierPropsList>
                <PropItem>
                    <p>{supplier.name}</p>
                    <p>{supplier.company}</p>
                </PropItem>
                <PropItem>
                    <p>{supplier.phone}</p>
                    <p>{supplier.email||"No email"}</p>
                </PropItem>
                <PropItem>
                    <Icon onClick={()=>showListMenu(supplier._id)}>
                        <More />
                    </Icon>
                    <p>{supplier.accNumb}</p>
                    <p>{supplier.bank}</p>
                </PropItem>
                <Divider />
            </SupplierPropsList>
            {
                menuId === supplier._id &&
                <MenuOptionsList
                    {...props}
                    onClick={() => {}}
                    onMouseLeave={() => closeMenu() }
                >
                    <Option onClick={(e: any) => opendEditor(e, 'editing')}>
                        <p style={{ display: 'block' }} className="lbl">Edit</p>
                    </Option>
                        <Option onClick={(e: any) => opendEditor(e, 'details')}>
                        <p style={{ display: 'block' }} className="lbl">Details</p>
                    </Option>
                        <Option onClick={(e: any) => deleteItem(e, supplier._id)}>
                        <p style={{ display: 'block' }} className="lbl">Delete</p>
                    </Option>
                </MenuOptionsList>
            }
        </SupplierCont>
    )
}
