import React, { useState } from 'react'
import { HeaderMenuOpt, Opt } from './styles'
import { MenuOpt } from './opts'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { showListMenu } from '../../../../src/globals'
import { queryOptions } from '../../../types/defaults'
import path from 'path'

const HeaderOptList = (props: any) => {
    // const modal = useReactiveVar(showListMenu)
    // const [optCat, setOptCat] = useState<any>('')

    const { menu, optCat, modal, setOptCatCallback } =  props;

    const { pathname } = useLocation();

    const setOpt = (opt: string) => {
        showListMenu('')
        queryOptions({
            ...queryOptions(),
            group: opt
        })
    }


    const setFilterOpt = (filter: string) => {
        showListMenu('')
        queryOptions({
            ...queryOptions(),
            filter
        })
    }

   
  return (
      <HeaderMenuOpt show={(menu === 'main') && modal !== ''}>
          {
                menu === 'main' && optCat==='' ?
                <>
                    <Opt onClick={() => setOptCatCallback('group')}>Group</Opt>
                    <Opt onClick={() => setOptCatCallback('filter')}>Filter</Opt>
                </>
                :
                optCat === 'group' ?
                <MenuOpt selectOptCallback={setOpt} options={
                          pathname == '/cashbook' ?
                              ['A-Z', 'Company', 'Date added']
                              : pathname === '/cashbook/payouts' ?
                                  ['A-Z', 'Supplier', 'Method', 'Date added']
                                  : pathname === '/sales' ?
                                      ['Customer', 'Method', 'Date added']
                                      :
                                      ['A-Z', 'Category', 'Date added']
                } />
                :
                <>
                    <MenuOpt selectOptCallback={setFilterOpt} options={
                        pathname == '/sales' ?
                        ['All', 'Pending']
                            : 
                            ['All','Out', 'Running out', 'Expired', 'Expiring']
                                
                    } />
                </>
                
          }
      </HeaderMenuOpt>
  )
}

export default HeaderOptList