
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card,  
    Footer, Header, ItemsCont, 
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import Invoice from './invoiceListItem'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

type Props = {}

function InvoiceCard(props: any) {

    const location = useLocation() as any

    const params = new URLSearchParams(location.search)

    const { group } = useReactiveVar(queryOptions)

    const { list:items, count, total, groupId } = props;

    const generateLink = () => {
        return `/sales/invoices?p=${group}&q=${encodeURIComponent(groupId)}`;
    }

    console.log(`gid:${groupId}`)
    
    return (
        <ItemsCont>
            {
            <>
                <Header>
                        <h6> { group === 'customer' ? groupId ||'NO NAME' : format_date(groupId)}</h6>
                    {
                        count > 5 &&
                        <h6>
                            <Link to={generateLink()}>SHOW ALL</Link>
                        </h6>
                    }
                </Header>
                <Card>
                    {
                        items.records.filter((r: any) => r.stocks.length > 0).map((record: any, i: number) => (
                            <Invoice
                                index={i}
                                key={record._id}
                                invoice={record}
                                count={count}
                                stock={record.stocks[0]}
                            />
                        ))
                    }
                    <Footer>
                        <Divider bottom={100} />
                        <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                        <Icon rot={90} size={6}>
                            <Chevron />
                        </Icon>
                        <h6>{roundAmount(total)}</h6>
                    </Footer>
                </Card>
            </>  
        }
        </ItemsCont>
    )
}

export default InvoiceCard