import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// import { Icon } from '../../../../components/icons/styles'
// import { invCriteria, queryOptions } from '../../../../types/defaults'
// import { Search } from '../../../explore/components/icons'
import { Form, Input, Item, Row, SearchSuggestions, Suggestions } from './styles'
// import { showListMenu } from '../../../../globals'
import { useReactiveVar } from '@apollo/client'
import { CheckIcon, More, SearchIcon, TimesIcn } from '../icons'
import { showListMenu } from '../../globals'
import { Icon, IconBtn } from '../icons/styles'
import { invCriteria, queryOptions } from '../../types/defaults'
import HeaderOptList from '../topnav/headers/tblOpt'
import { HeaderCont } from '../topnav/headers/styles'



const SearchBar = (props: any) => {
    const [focus, setFocus] = useState(false)
    const [query, setQuery] = useState('')
    const [queries, setQueries] = useState<any>([])
    const [menu, setMenu] = useState<any>('')


    const [optCat, setOptCat] = useState<any>('')
    const loc = useLocation()

    const { pathname } = useLocation();
    const params = new URLSearchParams(loc.search)

    const modal = useReactiveVar(showListMenu)

    const {pageTitle} = props

    const getSearchedTerms = async () => {
        try {
            let qs: any = await localStorage.getItem('queries');
            if (!qs) {

            } else {
                qs = JSON.parse(qs)?.filter((item: any) => item.page === pathname);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const openMenu = (menu: string) => {
        setOptCat('')
        setMenu(menu)
        showListMenu(menu)
    }


    const isPathTo = (name: string) => pathname.includes(name)

    useEffect(() => {
        getSearchedTerms();
        // console.log(optCat)
        // setShowSuggestions(searchModal)
    }, [focus]);


    const updateQueryHist = async (queries: any[]) => {
        try {
            await localStorage.setItem('queries', JSON.stringify(queries));
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e: any) => {

        const { value } = e.target
        setQuery(value)
        setFocus(false)
        // queryOptions({...queryOptions(), query: value})

        pathname === '/invoice' || pathname === '/invoices' ?
            invCriteria({
                ...invCriteria(),
                filter: '',
                query: value
            })
            :
            queryOptions({
                ...queryOptions(),
                query: value
            })
    }

    const handleSelection = (q: string) => {
        console.log(`query:${q}`)
        setQuery(q)
        let qs = queries
        qs = qs.map((item: any) => item.query === q ?
            ({
                ...item,
                dateAdded: new Date().getTime()
            })
            :
            item
        )
        setQueries([...qs]);
        updateQueryHist(qs)

        // pathname === '/invoice' || pathname === '/invoices' ?
        //     queryOptions({ 
        //         ...queryOptions(), 
        //     filter: '',
        //     query: q 
        // }) 
        // :
        queryOptions({
            ...queryOptions(),
            query: q
        })

        setFocus(false)
        // handleFocus(false)
        // page === 'expenses' ? expenseCriteria({ ...expCrt, query: suggestion })
        //     : page === 'invoice' ? invCriteria({ ...invCrt, filter: '', query: suggestion })
        //         : groupingCriteria({ ...criteria, query: suggestion, filter: '' })
    }
    const setOpt = (opt: string) => {
        showListMenu('')
        setMenu('')
        queryOptions({
            ...queryOptions(),
            group: opt
        })
    }


    function handleClear() {
        if (query.trim()) {
            const i = queries.findIndex((item: any) => item.query === query);
            if (i === -1) {
                queries.unshift({
                    query,
                    page: pathname,
                    dateAdded: new Date().getTime(),
                });
            } else {
                queries[i] = {
                    ...queries[i],
                    dateAdded: new Date().getTime()
                };
            }
            updateQueryHist(queries)
            setQuery('');

            pathname === '/invoice' || pathname === '/invoices' ?
                invCriteria({ ...invCriteria(), filter: '', query: '' }) :
                queryOptions({ ...queryOptions(), query: '' })
            // queryOptions({ ...queryOptions(), query: '' })
            setFocus(false)

        }

    }

    const handleFocus = ()=>{
        setFocus(true)
        showListMenu('search')
    }

    return (
        <HeaderCont id="container">
            <Row >
                <h4>{pageTitle}</h4>
                <Form
                    onSubmit={(e: SyntheticEvent) => e.preventDefault()}
                    focus={focus && modal!==''} 
                    {...props}
                    >
                    <Icon size={20}>
                        <SearchIcon />
                    </Icon>
                    <Input
                        value={query}
                        autoComplete='off'
                        placeholder='Search...'
                        onFocus={() => handleFocus()}
                        // onBlur={()=>setFocus(false)}
                        onChange={(e: SyntheticEvent) => handleChange(e)}
                    /> 
                    {
                        query && 
                        <IconBtn id="cancel" onClick={() => handleClear()} size={22} rot={90}>
                            <TimesIcn color="black" />
                        </IconBtn>
                    }
                    {
                        !query &&
                        <div id="more">
                            <Icon onClick={() => openMenu('main')} size={24} rot={90}>
                                <More />
                            </Icon>
                            <HeaderOptList setOptCatCallback={setOptCat} optCat={optCat} modal={modal} menu={menu} />
                        </div>
                    }
                    {
                        (focus && modal!=='') &&
                        <SearchSuggestions> {
                            queries?.map((q: any) => (
                                <Suggestions key={q.dateAdded} onClick={() => handleSelection(q.query)} >
                                    
                                    <p>{q.query}</p>
                                    <IconBtn size={22}>
                                        <TimesIcn color="black" />
                                    </IconBtn>
                                </Suggestions>
                            ))
                        }
                        </SearchSuggestions>
                    }
                </Form>
                <Item onClick={() => openMenu('main')} id='itm' className='item'>
                    <Icon onClick={() => openMenu('main')} size={22} id='group'>
                        <More color="grey" />
                    </Icon>
                    <HeaderOptList setOptCatCallback={setOptCat} optCat={optCat} modal={modal} menu={menu} />
                </Item>
               
            </Row>
        </HeaderCont>
    )
}

export default SearchBar

