import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import { LocalCheckOutModal } from '../../components/modals'
import InvoiceModal from '../../components/modals/invoice'
import { GET_INVOICES } from '../../graphql/queries/invoice.query'
import { queryOptions } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

// import MainHeader from './component/headers/main'

import { OuterList } from './component/list/outer'
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from './styles'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import InvoiceCard from '../../components/cards/invoice'
import SideNavigator from '../../components/sidenav'
import TableHeader from './component/headers/tablex'
import { CardLoader } from '../../components/cards/loaders'
import InvoiceDetails from '../expenses/components/details/invoice'
import ExpenseModal from '../../components/modals/expenses'

const InvoicePage = (props: any) => {

    const queries = useReactiveVar(queryOptions)
    const { search } = useLocation()

    let offset = 0;

    const { data, loading, error, refetch, fetchMore } = useQuery(
        GET_INVOICES, {
        variables: {
            ...queries,
            offset,
            group: queries.group || 'date added'
        },
        fetchPolicy: "network-only"
        }
    )

    let curInvoices: any[] = [];

    if (data) {
        curInvoices = data?.invoices
        offset = offset + 10;
        // console.log(data)
    }
    if (error) {
        console.log({...error})
    }

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.invoices?.length,
            }
        })
    }
    return (
        <>
            <Main>
                <Container id="container">
                    <TableHeader />
                    <ItemListCont>
                        { 
                            loading ?
                                <>
                                    <LoadingState />
                                    <CardLoader />
                                </>
                                :
                                error ?
                                    <ErrorState retryCallback={refetch} />
                                    :
                                    !data?.invoices?.length ?
                                        <EmptyState
                                            clickCallback={refetch}
                                            message='No sales records so far'
                                            btnLabel='Refresh'
                                            subMessage='Invoices appears here when created'
                                        />
                                        :
                                        <>
                                            <TableCont>
                                                <ListGroupCont>
                                                    <InfiniteScroll
                                                        dataLength={data?.invoices?.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        loader={<></>}
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                    {
                                                        curInvoices.map((group: any, i: number) => (
                                                            <OuterList
                                                                {...props}
                                                                list={group}
                                                                total={group.total}
                                                                count={group.count}
                                                                groupId={group._id}
                                                                key={group.records[0]._id}
                                                                nextRecords={curInvoices[i + 1]?.records || []}
                                                            />
                                                        ))
                                                    }
                                                    </InfiniteScroll>
                                                </ListGroupCont>
                                            </TableCont>
                                            <InfiniteScroll
                                                dataLength={data.invoices.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            > 
                                            {
                                                    curInvoices.map((group: any, i: number) => (
                                                        <InvoiceCard
                                                            {...props}
                                                            groupId={group._id}
                                                            list={group}
                                                            count={group.count}
                                                            total={group.total}
                                                            key={group.records[0]._id}
                                                            nextRecords={curInvoices[i + 1]?.records || []}
                                                        />
                                                    ))
                                                }
                                            </InfiniteScroll>
                                        </>
                        }
                    </ItemListCont>
                </Container>
                <SideNavigator />
                {/* <InvoiceModal /> */}
                <ExpenseModal />
                <InvoiceDetails />
                <LocalCheckOutModal />
                {/* <CheckOutOnlineModal /> */}
            </Main>
        </>
    )

}

export default InvoicePage