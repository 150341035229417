import React, { useEffect, useState } from 'react'
import { getLocalStore } from '../../utils';
import Inventory from '../inventory';

const Home = () => {
    const [accType, setToken] = useState('')
    useEffect(() => {
        const a = getLocalStore()?.info?.accType
        if (a) {
            setToken(a);
        }
    },[]);
    return (
            <Inventory />
    )
}

export default Home