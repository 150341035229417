import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../apollo'
import { Desc, DescCol, DescCont, Instock, MenuOptionsList, PriceCont, StockCont, StockPrice, Title, Option, TitleCont } from './styles'
import { ImageItem } from './image'
import { Divider, Icon } from '../../icons/styles'
import { Ellipsis } from '../../icons'
import { formatMoney } from '../../../utils'
import { globalInvoice } from '../../../globals/defaults'
import { showCashBookModal, tableState } from '../../../globals'

const Invoice = (props: any) => {
    // const {item, count, index}= props
    let { stock, invoice, opened, pendings, index, count } = props;
    const [showMenu, setShowMenu] = useState<any>(false)

    
// console.log(`index:${index}  count: ${count}`)
    const selectListOption = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }

    
    const showDetails = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: invoice
        })
        // globalInvoice(invoice)
        setShowMenu(false)
        showCashBookModal(true)
    }
  return (
      <StockCont>
          <ImageItem multiple={invoice.stocks.length > 1} source={getImageUrl(stock.item.mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184')} />
          <DescCol>
              <DescCont>
                  <TitleCont>
                      <Title>{stock.item.name}</Title>
                      <Icon onClick={(e: SyntheticEvent) => setShowMenu(true)} rot={90} >
                          <Ellipsis />
                      </Icon>
                      <MenuOptionsList show={showMenu}>
                          <Option onClick={(e: SyntheticEvent) => selectListOption(e)}>
                              <p>Edit</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => showDetails(e,'details')} >
                              <p>See details</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => selectListOption(e)}>
                              <p style={{ color: 'red' }}>Delete</p>
                          </Option>
                      </MenuOptionsList>
                  </TitleCont>
                  <Desc>{stock.item.description}</Desc>
              </DescCont>
              <PriceCont>
                  <Instock>{stock.quantity}</Instock>
                  <StockPrice style={{ color: invoice.completed ? '' : '#ff4400b3' }}>{formatMoney(stock.item.sellingPrice * stock.quantity)}</StockPrice>
              </PriceCont>
              {
                  index + 1 !== count && <Divider />
              }
          </DescCol>
      </StockCont>
  )
}

export default Invoice