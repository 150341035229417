import { makeVar } from '@apollo/client'
import { Expense, Product, FeedbackMsg, Invoice, Client, Person, Staff, PayoutModel } from './model'

export let initProduct: Product = {
    name: '',
    description: '',
    categories: {
        name: null,
        category: null
    },
    q: {
        val: 0,
        unit: ''
    },
    size: {val: '', unit: ''},
    form: '',
    publish: 'Both',
    status: 'Active',
    sellingPrice: 0,
    costPrice: 0,
    warningCount: 0,
    expiry: '',
    expiryWarning: 0,
    mediaUrls:[],
}
let defCustomer: Person = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phone: ''
}
export let initExpense: Expense = {
    _id: '',
    name: '',
    desc: '',
    spender: '',
    amount: 1,
    modifier: '',
}
export let initStaff: Staff = {
    firstName: '',
    lastName: '',
    department: '',
    position: '',
    phone: '',
    email: '',
    address: '',
    permisions: {
        creates: ['stocks', 'invoices', 'expenses'],
        edits: ['stocks', 'invoices', 'expenses'],
        deletes: ['stocks', 'invoices', 'expenses']
    },
    dp: 'a35f9e5f-771c-4afd-b4b7-0869842c8dc9',
    banner: 'd063578d-733d-4aca-8809-e18368d55184',
}
export let initAccount: Client = {
    name: '',
    category: '',
    password: '',
    phone: '',
    dp: 'a35f9e5f-771c-4afd-b4b7-0869842c8dc9',
    banner: 'd063578d-733d-4aca-8809-e18368d55184',
}
export let initInvoice: Invoice = {
    stocks: [],
    seenBy: [],
    recieved: 0,
    payable: 0,
    paymentMethod:'Cash',
    completed: true,
    modifier: '',
    customer: defCustomer,
}
export let initPayout: PayoutModel = {
    supplier:{
        name: '',
        phone: '',
    },
    paid: 0,
    amount: 0,
    method:'',
    modifier: '',
}
export let defClient: Client = {
    name: '',
    username: '',
}
export const initFeedback: FeedbackMsg = {
    success: true,
    msg: '',
    done: false
}


export const headerMenu = makeVar('');
export const invMenu = makeVar('');
export const queryOptions = makeVar({
    offset: 0,
    filter: '',
    query: '',
    group: '',
   
});
export const expenseCriteria = makeVar({
    query: '',
    group: 'date',
});
export const invCriteria = makeVar({
    offset: 0,
    query: '',
    filter: '',
    group: 'date',
});


export const hiddenModal = makeVar(false);
export const sharedModal = makeVar('');
export const globalInvoice:any = makeVar(initInvoice);
export const showSearchModal = makeVar(false);
export const tempClient = makeVar(defClient);



export const dummies =  [{
    id: "ksjdkjksksk",
    name: "Chocolate Candy",
    desc: 'Medium',
    price: 1.25,
    img: 'https://i.pinimg.com/564x/66/ff/1e/66ff1eeabca7938ca25c3a5af8b18f05.jpg'
},
 {
    id: "jhgkjhkgkhg",
    name: "Vanilla Ice Cream",
    desc: 'Large',
    price: 2700.50,
    img:'https://i.pinimg.com/564x/f1/69/60/f169605d45ca98bbcacc5ec60acb0a04.jpg'
    },
    {
        id: "lkjlkjlklkj",
        name: 'Loan Mower',
        desc: 'For Cleaning Rooms',
        price: 4700,
        img: 'https://i.pinimg.com/564x/f1/69/60/f169605d45ca98bbcacc5ec60acb0a04.jpg'

    },
    {
        id: "jjdkskskd",
        name: 'Johsosn Soda Cream',
        desc: 'Someone',
        price: 22200,
        img: 'https://i.pinimg.com/736x/2e/63/4a/2e634a1e68975e92a2effae9b04583ab.jpg'

    },
    {
        id: "mmmmkskks",
        name: 'Shower Jell',
        desc: 'For Men',
        price: 14200,
        img: 'https://i.pinimg.com/564x/25/a6/5a/25a65a12acbadc3c7a91bf4bafe0344e.jpg'

    },
    {
        id: "ssskkkkk",
        name: 'Hair Moiturizer',
        desc: 'Effective for Maintaing Hair Growth',
        price: 238800,
        img: 'https://i.pinimg.com/564x/f8/da/0b/f8da0bbc2d8f6d50ecf74b50a5ced3a1.jpg'

    },
    {
        id: "ssskkkkk",
        name: 'Skin Moisture',
        desc: 'Effective for Maintaing Hair Growth',
        price: 5900,
        img: 'https://i.pinimg.com/474x/cb/df/c2/cbdfc27dee7d0f3ad02f26904b6243d0.jpg'

    }
]
export const dummy_products =  [
    {
        _id: 'Appatizer',
        records: [
            {
                id: "ksjdkjksksk",
                name: "Chocolate Candy",
                description: 'Medium',
                sellingPrice: 1.25,
                mediaUrls:'https://i.pinimg.com/564x/66/ff/1e/66ff1eeabca7938ca25c3a5af8b18f05.jpg'
                    
                ,
                expiry: '2025-02-01T00:00:00.000 +00:00',
                q: {
                    val: 10,
                    unit: 'Catons'
                }
            },
            {
                id: "jhgkjhkgkhg",
                name: "Vanilla Ice Cream",
                description: 'Large',
                sellingPrice: 2700.50,
                q: {
                    val: 72,
                    unit: 'Cans'
                },
                mediaUrls: 'https://i.pinimg.com/474x/23/d6/57/23d657b4ab3340b40ba8c292958822a2.jpg'
                    
                ,
               
            },
        ]
    },
    {
        _id: 'Soap',
        records: [
            {
                id: "ksjjksksk",
                name: "Magerine",
                description: 'Medium',
                sellingPrice: 771.25,
                mediaUrls:'https://i.pinimg.com/474x/b9/4f/13/b94f131cceeeacf28dbadb656b4b21f9.jpg'
                    
                ,
                q: {
                    val: 10,
                    unit: 'Satchet'
                },
                expiry: '2024-04-01T00:00:00.000 +00:00'
                   
            },
            {
                id: "kkkmmmmsk",
                name: "Donuts",
                description: 'Large',
                sellingPrice: 3400.99,
                q: {
                    val: 122,
                    unit: 'Bottles'
                },
                mediaUrls:'https://i.pinimg.com/736x/a6/6c/34/a66c3421f5856598c0767822315be9b9.jpg',
                expiry: '2024-03-01T00:00:00.000 +00:00'
            },
            {
                id: "nnndmdm",
                name: 'Chicken Sausage',
                description: 'Spicy Chicken Meat',
                sellingPrice: 4700,
                q: {
                    val: 52,
                    unit: 'Catons'
                },
                mediaUrls: 'https://i.pinimg.com/564x/2c/7c/10/2c7c10e124383865f444bd298372f7a0.jpg'
                   
                ,
                expiry: '2024-04-04T00:00:00.000 +00:00'
            },
        ]
    },
    {
        _id: 'Ice Cream',
        records: [
            {
                id: "ssskkkkk",
                name: 'Skin Moisture',
                description: 'Effective for Maintaing Hair Growth',
                sellingPrice: 5900,
                q: {
                    val: 127,
                    unit: 'Packs'
                },
                mediaUrls: 'https://i.pinimg.com/736x/9f/5a/e3/9f5ae3cc5a909c824af3c7fb0b8a80d3.jpg',
                 expiry: '2024-04-11T00:00:00.000 +00:00'
            }
        ]
    },
    {

                _id: "Groceries",
                records: [
                    {
                        id: "jjdkskskd",
                        name: 'Johsosn Soda Cream',
                        description: 'Someone',
                        sellingPrice: 22200,
                        q: {
                            val: 10,
                            unit: 'Tins'
                        },
                        expiry: '2024-04-01T00:00:00.000 +00:00',
                        mediaUrls:'https://i.pinimg.com/736x/2e/63/4a/2e634a1e68975e92a2effae9b04583ab.jpg'
                        ,
                            
                       

                    },
                    {
                        id: "mmmmkskks",
                        name: 'Shower Jell',
                        description: 'For Men',
                        sellingPrice: 14200,
                        q: {
                            val: 2,
                            unit: 'Packs'
                        },
                        expiry: '2024-04-11T00:00:00.000 +00:00',
                        mediaUrls: 'https://i.pinimg.com/564x/25/a6/5a/25a65a12acbadc3c7a91bf4bafe0344e.jpg'
                    },
                    {
                        id: "ssskkkkk",
                        name: 'Hair Moiturizer',
                        description: 'Effective for Maintaing Hair Growth',
                        sellingPrice: 238800,
                        q: {
                            val: 4,
                            unit: 'Pcs'
                        },
                        expiry: '2024-04-11T00:00:00.000 +00:00',
                        mediaUrls: 'https://i.pinimg.com/564x/f8/da/0b/f8da0bbc2d8f6d50ecf74b50a5ced3a1.jpg'

                    },
                ]
            }
        

]