
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, CardCont
} from './styles'
import { formatMoney, format_date, roundAmount } from '../../../utils'

import ExpenseListItem from './item'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import PayoutItem from './item'
import { PayoutModel } from '../../../types/model'

type Props = {}

function PayoutCard(props: any) {

    const { list: payouts, count, totalAmount,totalBal, groupId } = props;
    const { group } = useReactiveVar(queryOptions)
    
    return (
        <CardCont>
             <Header>
                {(group === 'date added' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}
            </Header> 
            <Card>
                {
                    payouts.map((payout: PayoutModel, index: number) => (
                        <PayoutItem count={count} index={index} key={payout._id} payout={payout}  />
                 ))
                }
                <Footer>
                    <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                    <Icon rot={90} size={6}>
                        <Chevron />
                    </Icon>
                    <h6>
                       {formatMoney(totalBal)}
                    </h6>
                </Footer>
            </Card>
        </CardCont>
       
    )
}

export default PayoutCard