
import { gql} from '@apollo/client'

export const GET_SUPPLIERS = gql`
    query getSuppliers( $query: String, 
        $filter: String, 
        $group: String, 
        $offset: Int) {
        suppliers(query: $query,filter: $filter, group: $group, offset: $offset) {
            _id 
            records {
                _id
                name
                email
                accNumb
                bank
                phone
                company
                modifier
                createdAt
                updatedAt
            }
            count
        }
    }
`
export const GET_ALL_SUPPLIERS = gql`
    query getAllSuppliers {
        allSuppliers {
            _id
            name
            phone
        }
    }
`



