import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { cart, showCashBookModal, showCheckOutModal, showListMenu, showNav } from '../../globals'
// import { LocalCheckOut } from '../../pages/stocks/components/checkout'
import { CheckOutModalCont, GhostModalCont, ModalContainer } from './styles'
import { LocalCheckOut } from '../checkout/offline'

const Modal = () => {
    const navOpned = useReactiveVar(showNav) 
  return (
      <ModalContainer onClick={() =>showNav(false)} opened={navOpned}/>
  )
}
export const GhostModal = () => {
    const id = useReactiveVar(showListMenu)
    
  return (
    <GhostModalCont onClick={() => showListMenu('')} show={id!==''}/>
  )
}





export const LocalCheckOutModal = () => {
  const show = useReactiveVar(showCheckOutModal)

  const close = () => {
    showCheckOutModal(false)
  }

  return (
    <CheckOutModalCont onClick={close} opened={show} >
      <LocalCheckOut />
    </CheckOutModalCont>
 
  )
}

export default Modal


