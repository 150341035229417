import { useMutation, useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"

import { DetContainer, HeaderCont, Item, ListItem } from "./styles"
import { showCashBookModal, tableState } from "../../../../globals"
import { initSupplier } from "../forms/suppliers"
import { Divider, Icon } from "../../../../components/icons/styles"
import { format_date } from "../../../../utils"
import { CancelBtn, CloseBtn } from "../buttons/styles"
import { CancelIcon, TimesIcn } from "../../../../components/icons"



const SupplierDetails= (props: any) => {
    
    const { state, item } = useReactiveVar(tableState)

    let [supplier, setSupplier] = useState(initSupplier);

    useEffect(() => {
        setSupplier(item || initSupplier)
    }, [item])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    const closeDetails = () => {
        tableState({
            state: '',
            item: null
        })
        showCashBookModal(false)
    }

    return (
        <DetContainer show={state==='details'}>
            <HeaderCont>
                <h6>Supplier details</h6>
                <CloseBtn title="Close" onClick={closeDetails}>
                    <Icon>
                        <TimesIcn size={8} color='black'/>
                    </Icon>
                </CloseBtn>
            </HeaderCont>
            <ListItem>
                <Item>
                    <p>Name</p>
                    <p>{supplier.name}</p>
                </Item>
                <Item>
                    <p>Company</p>
                    <p>{supplier.company||'-'}</p>
                    
                    <Divider />
                </Item>
                <Item>
                    <p>Phone</p>
                    <p>{supplier.phone}</p>
                </Item>
                <Item>
                    <p>Email</p>
                    <p>{supplier.email||"-"}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Account No.</p>
                    <p>{supplier.accNumb || "-"}</p>
                </Item>
                <Item>
                    <p>Bank</p>
                    <p>{supplier.bank || "-"}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Date added</p> 
                    <p>{format_date(supplier?.createdAt||new Date())}</p>
                </Item>
                <Item>
                    <p>ID</p> 
                    <p>{supplier?._id?.slice(-6).toLocaleUpperCase()}</p>
                </Item>
            </ListItem>
        </DetContainer>
    )
}
export default SupplierDetails