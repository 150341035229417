import { gql } from '@apollo/client'

export const SAVE_PAYOUT = gql`
    mutation savePayout($payout: PayoutInput!, $mode:String) {
        savePayout(payout: $payout, mode:$mode) { 
            _id
             supplier {
                _id
                name
                phone
            }
            amount
            paid
            method
            remark
            modifier
            createdAt
            updatedAt
        }
    }
`
export const DELETE_PAYOUT = gql`
    mutation deletePayout( $id: String!) {
        deletePayout(id: $id) {
            _id
            amount
            paid                                                             
        }
    }
`
