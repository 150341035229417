
import { getImageUrl } from "../../../apollo"
import { getBg, simplifyExpDate } from "../../../utils"
// import { DateIndictor, StockIndicator } from "../../images/styles"
import { DateIndictor, ImageCont, StockIndicator } from "./styles"

const ImageItem = (props: any) => {
    const { source, name, expiry, expiryStatus } = props
    // const exp = expiry ? simplifyExpDate(expiry) : null

    return (
        <ImageCont bg={getBg()}>
            <div>{name.charAt(0)}</div>
          
            {/* <img src='https://i.pinimg.com/474x/72/50/b5/7250b57c77ac4aad649f6d484a045142.jpg' alt="" /> */}
            
        </ImageCont>
    )
}
export { ImageItem }