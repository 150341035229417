import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { AddFillIcon,CheckFillIcon,More } from '../../../../components/icons'
import { Divider, Icon, IconBtn, IconBtn2 } from '../../../../components/icons/styles'
import { cart, showInventoryModal, showStockEditModal, tableState } from '../../../../globals'
import { initInvoice } from '../../../../globals/defaults'
import { ADD_PRODUCT, DELETE_PRODUCT, SAVE_PRODUCT } from '../../../../graphql/mutations'
import { GET_PRODUCTS } from '../../../../graphql/queries'
import { queryOptions, sharedModal } from '../../../../types/defaults'
// import { CartItem, Invoice, Product as StockModel } from '../../../../types/model'
import { getExpStatus, stripTypename } from '../../../../utils'
import ImageItem from '../image'
import { MoreActions } from '../listMenu'
import { ImageCont, StockCont, PropItem, StockPropsList, AddItemCont, AddAutoForm, AddItemBtn } from './styles'
import { getImageUrl } from '../../../../apollo'
import { CartItem, Invoice, Product as StockModel } from '../../../../models'
import { updateProdCache } from '../../../../utils/caches'
import { Product } from '../../../../types/model'

export const Stock = (props: any) => {
  const [showMenu, setShowMenu] = useState(false)
  const { _id, name, owner, description, categories, expiry, q, mediaUrls, sellingPrice } = props.stock
  let { stock, account } = props;

  const queries = useReactiveVar(queryOptions)

  const isOwned = () => owner === account._id
  const myCart = useReactiveVar(cart)

  const openMenu = (e: any) => {
    e.stopPropagation(); 
    setShowMenu(true)
  }

  const opendEditor = (e:any, state: string) => {
    e.stopPropagation();
    tableState({
      ...tableState(),
      state,
      item: props.stock,
    })
    showInventoryModal(true)
  }
  const showSharingForm = (e: any) => {
    e.stopPropagation();
    sharedModal(_id) 
  }

  const convertStringToDate = (date: string) => {
    let d = date.split("/");
    return new Date(`20${d[1]}-${d[0]}-01`);
  };


  const [submit, { loading: isLoading, error: e, data }] = useMutation(ADD_PRODUCT, {
    update: (cache, { data: { saveProduct: newProduct } }) => {
      const data: any = cache.readQuery({
        query: GET_PRODUCTS,
        variables: {
          ...queries
        }
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          products: updateProdCache(data?.products, newProduct, queries.group),
        },
      });
      // showFeedBack();
    },
  });

  const handleSubmit = async (e:any) => {
    e.preventDefault()
    e.stopPropagation()
    let newProduct: Product = stock;

      const { __typename, q, size, categories, added, modified, ...s } = stock;
      newProduct = {
        ...s,
        q: stripTypename(q),
        size: stripTypename(size),
        added: stripTypename(added),
        modified: stripTypename(modified),
        categories: stripTypename(categories),
      };
    
    const { expiry } = newProduct;

    console.log(`new prod: ${newProduct}`)

    submit({
      variables: {
        product: {
          ...newProduct,
          expiry: expiry ? convertStringToDate(expiry) : expiry,
        },
      },
    });
  };

  const [deleteProduct, { error, loading }] = useMutation(DELETE_PRODUCT, {
    update: (cache, { data: { deleteProduct:deleted } }) => {
      const cached: any = cache.readQuery({
        query: GET_PRODUCTS,
        variables: {
          ...queryOptions()
        }
      })

      let newProducts = cached.products.map((prods: any) => {
        return (prods.records.some((r: any) => r._id === deleted._id)) ?
          ({
            ...prods,
            records: prods.records.filter((p: any) => p._id !== deleted._id)
          })
          :
          prods
      })
      cache.writeQuery({
        query: GET_PRODUCTS,
        variables: {
          ...queryOptions()
        },
        data: {
          products: newProducts.filter((p: any) => p.records.length)
        }
      });
    }
  });

  if (error) console.log({ error })

  const handelDeleteProduct = (id: string, e: any) => {
    e.stopPropagation();
    deleteProduct({
      variables: {
        id
      },
    })
  }

  const closeMenu = () => {
    setShowMenu(false)
  }
  
  const updateCart = (s: StockModel) => {
    if(s.q.val<=0) return
    const { __typename, ...striped} = s;
    let { stocks } = cart()
    const i = stocks.find((item: CartItem) => item._id === s._id)

    stocks = (!i) ?
      [
        ...stocks, {
          _id: stock._id,
          quantity: 1,
          booked: false,
          delivered: 0,
          item: striped,
        }
      ]
      :
      stocks.filter((s: any) => s._id !== i._id)

     cart({...cart(), stocks})
    
  }
  return (
    <StockCont
      onClick={() => updateCart(stock)}
      onMouseLeave={() => closeMenu()}
      selected={
        myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
      }
    >
      <ImageItem
        expiry={expiry}
        source={mediaUrls[0]}
        expiryStatus={getExpStatus(stock)}
       />
      <StockPropsList>
        <PropItem 
          selected={ myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p>{name}</p>
          <p>{description}</p> {
            isOwned() ? 
            <AddItemCont>
              <IconBtn2 size="17">
                <AddFillIcon color='lightgrey' />
              </IconBtn2>
            </AddItemCont>
            :
            <AddAutoForm>
                <AddItemBtn onClick={handleSubmit} className='adder'>
                  <IconBtn size="28">
                    <AddFillIcon color='black' />
                  </IconBtn>
                </AddItemBtn>
            </AddAutoForm>
          }
          
        </PropItem>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p>{categories?.name||'---'}</p>
          {/* <p>{categories?.category}</p> */}
        </PropItem>
        <PropItem 
          selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p></p>
        </PropItem>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <Icon onClick={(e: any) => openMenu(e)}>
            <More />
          </Icon>
          <p>{sellingPrice}</p>
        </PropItem>
        <Divider />
      </StockPropsList>
      {
        showMenu &&
        <MoreActions
          closeMenuCallback={closeMenu}
          sharingCallback={showSharingForm}
          actions={
            [
              {
                label: 'Edit',
                callback: (e: any) => opendEditor(e,'editing'),
              },
              {
                label: 'Stock Info',
                callback: (e: any) => opendEditor(e,'info'),
                permitted: true
              },
              {
                label: 'Delete Stock',
                callback: (e: any) => handelDeleteProduct(_id, e),
                // permitted: isAdmin() || can('delete', 'stocks')
              },
            ]
          }
        />
      }
    </StockCont>
  )
}
