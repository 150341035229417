import { useMutation, useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"

import { DetContainer, HeaderCont, Item, ListItem } from "./styles"
import { showCashBookModal, tableState } from "../../../../globals"
import { initSupplier } from "../forms/suppliers"
import { Divider, Icon } from "../../../../components/icons/styles"
import { formatMoney, formatTime, format_date } from "../../../../utils"
import { CancelBtn, CloseBtn } from "../buttons/styles"
import { CancelIcon, TimesIcn } from "../../../../components/icons"
import { initPayout } from "../../../../types/defaults"



const PayoutDetails = (props: any) => {

    const { state, item } = useReactiveVar(tableState)

    let [payout, setPayout] = useState(initPayout as any);

    useEffect(() => {
        setPayout(item || initPayout)
    }, [item])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    

    return (
        <DetContainer show={state === 'details'}>
            <HeaderCont>
                <h6>Payout details</h6>
                <CloseBtn title="Close" onClick={(e: any)=>close(e)}>
                    <Icon>
                        <TimesIcn size={8} color='black' />
                    </Icon>
                </CloseBtn>
            </HeaderCont>
            <ListItem>
                <Item>
                    <p>Supplier</p>
                    <p>{payout.supplier.name}</p>
                </Item>
                <Item>
                    <p>Phone</p>
                    <p>{payout.supplier.phone || '-'}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Description</p>
                    <p>{payout.remark}</p>
                </Item>
                <Item>
                    <p>Expected amount</p>
                    <p>{formatMoney(payout.amount)}</p>
                </Item>
                <Item>
                    <p>Amount paid</p>
                    <p>{formatMoney(payout?.paid)}</p>
                </Item>
                <Item>
                    <p>Balance</p>
                    <p>{formatMoney((payout?.amount ?? 0) - (payout?.paid ?? 0))}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Date created</p>
                    <p>{format_date(payout.createdAt)} {formatTime(payout?.createddAt || new Date()).toLowerCase()}</p>

                </Item>
                <Item>
                    <p>Last modified</p>
                    <p>{format_date(payout?.updateddAt || new Date())} {formatTime(payout?.updateddAt ||new Date()).toLowerCase()}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Payment ID</p>
                    <p>{payout?._id?.slice(-6).toLocaleUpperCase()}</p>
                </Item>
            </ListItem>
        </DetContainer>
    )
}
export default PayoutDetails