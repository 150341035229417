
import { getBg } from "../../../../../../utils"
import {ImageCont} from "./styles"

const ImageItem = (props: any) => {
    const {name } = props
    // const exp = expiry ? simplifyExpDate(expiry) : null

    return (
        <ImageCont bg={getBg()}>
            <div>{name?.charAt(0)}</div>
        </ImageCont>
    )
}
export { ImageItem }