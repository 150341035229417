import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../apollo'
import { Desc, DescCol, DescCont, MenuOptionsList, Mail, Phone, Title, Option, TitleCont, PayoutCont, ContactCont } from './styles'
import { ImageItem } from './image'
import { Divider, Icon } from '../../icons/styles'
import { Ellipsis } from '../../icons'
import { showInventoryModal, tableState } from '../../../globals'
import { GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { useMutation } from '@apollo/client'
import { DELETE_PRODUCT } from '../../../graphql/mutations'

const HistoryItem = (props: any) => {
    const {item, name, count, index}= props
    const [showMenu, setShowMenu] = useState<any>(false)

    const selectListOption = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }
    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item,
        })
        setShowMenu(false)
        showInventoryModal(true)
    }

    const [deleteProduct, { error, loading }] = useMutation(DELETE_PRODUCT, {
        update: (cache, { data: { deleteProduct: deleted } }) => {
            const cached: any = cache.readQuery({
                query: GET_PRODUCTS,
                variables: {
                    ...queryOptions()
                }
            })

            let newProducts = cached.products.map((prods: any) => {
                return (prods.records.some((r: any) => r._id === deleted._id)) ?
                    ({
                        ...prods,
                        records: prods.records.filter((p: any) => p._id !== deleted._id)
                    })
                    :
                    prods
            })
            cache.writeQuery({
                query: GET_PRODUCTS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    products: newProducts.filter((p: any) => p.records.length)
                }
            });
        }
    });

    if (error) console.log({ error })

    const handelDeleteProduct = (id: string, e: any) => {
        e.stopPropagation();
        deleteProduct({
            variables: {
                id
            },
        })
    }
  return (
      <PayoutCont>
          <ImageItem name={name} />
          <DescCol>
              <DescCont>
                  <TitleCont>
                      <Title>{name}</Title>
                      <Icon onClick={(e: SyntheticEvent) => setShowMenu(true)} rot={90} >
                          <Ellipsis />
                      </Icon>
                      <MenuOptionsList show={showMenu}>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e,'editing')}>
                              <p>Edit</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => selectListOption(e)} >
                              <p>Info</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent)=> {}}>
                              <p style={{ color: 'red' }}>Delete</p>
                          </Option>
                      </MenuOptionsList>
                  </TitleCont>
                  <ContactCont>
                      <Phone>10:47am</Phone>
                      <Mail>100,000</Mail>
                  </ContactCont>
                  {/* <Desc>Emzor Co.</Desc> */}
              </DescCont>
              {/* <ContactCont>
                  <Phone>08022778054</Phone>
                  <Mail>aminu@example.com</Mail>
              </ContactCont> */}
              {
                  index + 1 !== count && <Divider bottom={-12} />
              }
          </DescCol>
      </PayoutCont>
  )
}

export default HistoryItem