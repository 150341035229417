import React, { useState } from 'react'
import { Chevron, Compress, LineWeight, Plus } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { showInventoryModal, tableState } from '../../../../../globals'
import { IconBtn } from '../../buttons/styles'
import SearchForm from '../../forms'
import Actions from '../main/menu'

import { HeaderCont, HeaderListItems, HeaderMainCont, Item, ItemCont } from './styles'
import { FilterCont } from '../main/styles'
import { GROUP_OPTION } from '../main'
import LinksHeader from '../main/sub'





export const TableHeader = () => {
    const showEditForm = () => {
        //show modal to edit item details.
        showInventoryModal(true)
        // tableState({item: null, state: 'editing'})
    }
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>STOCK</h6>
                </Item>
                <Item>
                    <h6>CATEGORY</h6>
                </Item>
                <Item>
                    <h6></h6>
                </Item>
                <Item>
                    <h6>
                        AVERAGE PRICE
                        <span>
                            <IconBtn onClick={() => showEditForm()}>
                                <Icon>
                                    <Plus />
                                </Icon>
                            </IconBtn>
                        </span>
                    </h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}



const CatHeader = (props: any) => {
    const [active, setActive] = useState('')
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showInventoryModal(true)
    }

    return (
        <HeaderMainCont>
            <HeaderCont>
                <HeaderListItems>
                    <h6>Inventory</h6>
                    <SearchForm />
                    <FilterCont>
                        <div className='filter__cont'>
                            <ItemCont title='Group' >
                                <Item id='itm' className='item' onClick={() => setActive('group')}>
                                    <Icon id='group'>
                                        <LineWeight />
                                    </Icon>
                                    <Icon id='cheveron'>
                                        <Chevron />
                                    </Icon>
                                    {active === 'group' && <Actions closeCallback={() => setActive('')} name='group' options={GROUP_OPTION} />}
                                </Item>
                            </ItemCont>
                            <ItemCont title='Filter'>
                            </ItemCont>
                        </div>
                    </FilterCont>
                </HeaderListItems>
            </HeaderCont>
            <LinksHeader />
        </HeaderMainCont>
    )
}

export default CatHeader