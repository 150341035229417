import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { showNav, showNavModal } from '../../globals'
import { HomeIcn, Invoice, Sparkle, Scroll, Analytics, Alt, SquareGrid, User, HandShake, ReactagleArrowRight, StoreFront, BookIcn, ListBook } from '../icons'
import { Icon } from '../icons/styles'
import { HamdbugerMenu, LinkItem, LinkItemsCont, NavCont, NavHeaderCont, NavModal } from './styles'
import { useNavigate } from 'react-router-dom'
import { getLocalStore, inDevelopment } from '../../utils'
import { useLocation } from 'react-router-dom'

const SideNavigator = (props: any) => {
  const [navMenu, setNavMenu] = useState(false)
  const modal = useReactiveVar(showNavModal)
  // const navOpned = useReactiveVar(showNav)

  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [accType, setAccType] = useState(''); 

  const signInOut = () => {
    if(token){
      if (inDevelopment) {
        localStorage.clear()
      } else {
        localStorage.removeItem('account')
        localStorage.removeItem('token')
      }
      navigate("/");
    }
    navigate("/signin");
  }


  useEffect(() => {
    const t = localStorage.getItem('token') as any;
    if (t) {
      const account = getLocalStore()
      if (account) {
        setToken(t);
        setAccType(account.info?.accType)
      }
    }
  }, [modal]);


  useEffect(() => {
     setNavMenu(modal)
  }, [modal])

  const toggleSideNav = () => {
    setNavMenu(!navMenu)
    showNavModal(navMenu)
  }
  const close = () => {
    setNavMenu(false)
    showNavModal(false)
  }

  const { pathname } = useLocation()

  const isPathTo = (name: string) => pathname.includes(name)

  const name: any = () => !pathname.split('/') || pathname.split('/').length === 2 ? `/${pathname.split('/').filter(Boolean).pop()}` : pathname.split('/').filter(Boolean).pop();
 
  return (
    <>
   <NavModal opened={navMenu||modal} onClick={close}/>
      <NavCont opened={navMenu || modal
      
      }>
        <NavHeaderCont onClick={toggleSideNav}>
        <HamdbugerMenu onClick={toggleSideNav} id='dsk'>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div> 
          </HamdbugerMenu>
        </NavHeaderCont>
        <LinkItemsCont>
          <LinkItem active={pathname==='/'}>
            <NavLink to='/' title='Home'> 
              <Icon>
                <HomeIcn />
              </Icon>
              <h6>Inventory</h6>
            </NavLink>
          </LinkItem>
          <LinkItem active={isPathTo('/sales')}>
            <NavLink to='/sales' title='Sales'>
              <Icon id="payment">
                <HandShake />
              </Icon>
              <h6>Sales</h6>
            </NavLink> 
          </LinkItem>
          <LinkItem active={isPathTo('cashbook')}>
            <NavLink to='/cashbook' title='Cash Book'>
              <Icon id="cb">
                <BookIcn />
              </Icon>
              <h6>Cash Book</h6>
            </NavLink>
          </LinkItem>
          <LinkItem active={isPathTo('expenses')}>
            <NavLink to='/expenses' title='Expenses'>
              <Icon id="exp">
                <ListBook />
              </Icon>
              <h6>Expenses</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <NavLink active={isPathTo('analytics')} to='/analytics' title='Analytics'>
              <Icon id='account'>
                <Analytics />
              </Icon>
              <h6>Analytics</h6>
            </NavLink>
          </LinkItem>
          
        </LinkItemsCont>
        <LinkItemsCont className='sign'>
          <LinkItem className="acc" active={isPathTo('account')}>
            <NavLink to='/account' title='Account'>
              <Icon id="account">
                <User />
              </Icon>
              <h6>Account</h6>
            </NavLink>
          </LinkItem>
          <LinkItem>
            <p onClick={signInOut} title='Sign out'>
              <Icon id="alt">
                <Alt />
              </Icon>
              <h6>{token ? 'Sign out' : 'Sign in'}</h6>
            </p>
          </LinkItem>
        </LinkItemsCont>
      </NavCont>
    </>
  //  </NavModal>
  )
}

export default SideNavigator
