
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, CardCont
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import ExpenseListItem from './item'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import HistoryItem from './item'

type Props = {}

function PayoutHistoryCard(props: any) {

    const { list: items, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)
    
    return (
        <CardCont>
             <Header>
                {(group === 'date' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}
                {/* <Divider /> */}
            </Header> 
            <Card>
                {
                    // items.map((item: any, index: number) => (
                    <HistoryItem name="Hassan Usman"  />
                       
                    // ))
                }
                <HistoryItem name="Mohamed Ibrahim" />
                <HistoryItem name="Chinedu Okoro" />
                <Footer>
                    <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                    <Icon rot={90} size={6}>
                        <Chevron />
                    </Icon>
                    <h6>
                        N1,255,000
                        {/* {roundAmount(total)} */}
                    </h6>
                </Footer>
            </Card>
        </CardCont>
       
    )
}

export default PayoutHistoryCard