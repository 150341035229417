
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, 
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import StockListItem from './stockListItem'
import Skeleton from '../../loaders/skeletons'
import { useReactiveVar } from '@apollo/client'
import { queryOptions } from '../../../types/defaults'
import { Link } from 'react-router-dom'

type Props = {}

function StocksCard(props: any) {

    const { list: items, count, total, groupId } = props;

    const { group, filter } = useReactiveVar(queryOptions)

    const generateLink = (group: string, id: string) => {
        return `/stocks?p=${group || 'category'}&q=${encodeURIComponent(id)}&r=${filter}`;
    }
    
    return (
        <>
            <Header>
                <p>{group ==='date added' ? format_date(groupId) : groupId}</p>
                 {
                    count-5 > 0 &&
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                }
            </Header>
            <Card>

                {
                    items.map((item: any, index: number) => (
                        <StockListItem index={index} stock={item} count={items.length} key={item._id} />
                    ))
                }
                <Footer>
                    <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                    <Icon rot={90} size={6}>
                        <Chevron />
                    </Icon>
                    <h6>{roundAmount(total)}</h6>
                </Footer>
            </Card>
        </>
        
    )
}

export default StocksCard