import { useMutation, useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"
import { Cancel, Chevron, Drop, TimesIcn } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { showInventoryModal, tableState } from "../../../../../globals"
import { SAVE_PRODUCT, UPLOAD_FILE } from "../../../../../graphql/mutations"
import { GET_PRODUCTS } from "../../../../../graphql/queries/product.query"
import { queryOptions, initProduct } from "../../../../../types/defaults"
import { Product } from "../../../../../types/model"
import { formatMoney, getLocalStore, simplifyExpDate, stripTypename } from "../../../../../utils"
import { updateProdCache } from "../../../../../utils/caches"
import Button, { SaveButton } from "../../buttons"
import { Input } from "../inputs"
import DropDown from "../inputs/dropdown"
import DropDownList, { SingleDropDownList } from "../inputs/dropdown/listoptions"
import { Double } from "../styles"
import { BodyCont, BtnCont, Column, Sec1, FileInput, Form, FormContainer, FormGroup, FormGroupCont, HeaderCont, ImageCont, Sec2, BackLink, ImageGroup } from "./styles"
import { Loader } from "../../../../../components/loaders"
import { Mask } from "../share/imageView/styles"
import { getImageUrl } from "../../../../../apollo"
import { CloseBtn, NextBtn } from "../../buttons/styles"


export const categories = [
    {
        name: 'Foods',
        items: [
            "Bakery and Bread",
            "Beans",
            "Breakfast",
            "Burgers",
            "Chicken",
            "Dairy and Eggs",
            "Deserts",
            "Drinks",
            "Main Course",
            "Fresh Produce",
            "Frozen Foods",
            "Grills",
            "Ice Cream",
            "Juices",
            "Pasta",
            "Drinks",
            "Pizza",
            "Rice",
            "Salads",
            "Sandwiches",
            "Shawarma",
            "Appetizers",
            "Soups",
            "Beverages",
            "Ethnics",
            "Specials",
            "Spices and Seasonings",
            "Vegetables", 
            'Others'
        ]
    }
    ,
    {
        name: 'Cosmetics',
        items: [
            'Cleansers',
            'Toners',
            'Moisturizers',
            'Sunscreen',
            'Masks',
            'Foundation',
            'Concealer',
            'Powder',
            'Primer',
            'Eyeshadow',
            'Eyeliner',
            'Mascara',
            'Lipstick',
            'Lip Gloss',
            'Lip Liner',
            'Blush',
            'Bronzer',
            'Highlighter',
            'Body Spray',
            'Cream',
            'Shampoo',
            'Conditioner',
            'Hair Masks',
            'Hair Spray',
            'Mousse',
            'Gel',
            'Hair Oil',
            'Perfume',
            'Eau de Toilette',
            'Body Mist',
            'Nail Polish',
            'Nail Polish Remover',
            'Nail Care Tools',
            'Makeup Brushes',
            'Sponges and Applicators',
            'Mirrors',
            'Curling Irons',
            'Straighteners',
            'Hair Dryers',
            'Shaving Cream',
            'Aftershave',
            'Beard Oil',
            'Beard Balm',
            'Body Wash',
            'Body Lotion',
            'Body Scrub',
            'Hand Cream',
            'Soap',
            'Bath Bombs and Salts',
            'Acne Treatment',
            'Anti-Aging Products',
            'Specialty Serums',
            'Others'
        ].sort()
    },
    {
        name: 'Fashion',
        items: [
            'Blouses',
            'Sweaters',
            'Tanks & Camis',
            'Jeans',
            'Trousers',
            'Skirts',
            'Casual Dresses',
            'Formal Dresses',
            'Evening Dresses',
            'Cocktail Dresses',
            'Coats',
            'Jackets',
            'Blazers',
            'Yoga Pants',
            'Sports Bras',
            'Hoodies',
            'Leggings',
            'Lingerie',
            'Pajamas',
            'Robes',
            'Bras',
            'Bikinis',
            'One-Pieces',
            'Cover-Ups,',
            'Joggers',
            'Hoodies',
            'Shorts',
            'T-Shirts',
            'Boxers',
            'Briefs',
            'Pajamas',
            'wim Trunks',
            'Board Shorts',
            'Onesies',
            'Rompers',
            'Tops',
            'Bottoms',
            'Sleepwear',
            'Necklaces',
            'Earrings',
            'Bracelets',
            'Rings',
            'Caps',
            'Beanies',
            'Sun Hats',
            'Scarves & Gloves',
            'Belts',
            'Sunglasses',
            'Watches',
            'Heels',
            'Flats',
            'Boots',
            'Sneakers',
            'Dress Shoes',
            'Boots',
            'Sneakers',
            'Sandals',
            'Loafers',
            'Others'
        ].sort()
    },
    {
        name: 'Pharmaceuticals',
        items: [
            'Analgesics',
            'Anti-Allergics',
            'Anti-Biotics',
            'Anti-Diabetic',
            'Anti-Epileptic',
            'Anti-Fungal',
            'Anti-Histamines',
            'Anti-Hypertensive',
            'Anti-Inflammatory',
            'Anti-Malaria',
            'Anti-Microbials',
            'Anti-Tuberculosis',
            'Antacids',
            'Anthelmintics',
            'Antivirals',
            'CNS', // Central Nervous System
            'Cough, Cold & Flu',
            'Corticosteroids',
            'Devices',
            'Drops',
            'Endocrine',
            'Eye Care',
            'Gastro-Intestinals',
            'GIT Drugs', // Gastrointestinal Drugs
            'Hormonal Therapy',
            'Injectable',
            'Infusions',
            'Laxatives',
            'Miscellaneous',
            'Nutraceuticals',
            'Oral Care',
            'Pain Relief',
            'Poison Box',
            'Reproductive Health',
            'Respiratory Care',
            'Skin Care',
            'Supplements',
            'Surgicals',
            'Topicals',
            'Vaccines',
            'Vitamin',
            'Wound Care',
            'Others'
        ]
    },

    {
        name: 'Provisions',
        items: [
            'Grocery',
            'Toiletries',
            'Beverages',
            'Dairy and Eggs',
            'Frozen Foods',
            'Snacks',
            'Drinks',
            'Cereal and Breakfast Foods',
            'Canned and Packaged Meat',
            'Condiments',
            'Spices and Seasonings',
            'Bakery and Bread',
            'Fresh Produce',
            'Household and Cleaning Supplies',
            'Personal Care',
            'Health and Wellness',
            'Baby and Infant Care',
            'Pet Care',
            'Batteries and Light Bulbs',
            'Office and School Supplies',
            'Cookware and Utensils',
            'Candles and Matches',
            'Specialty and Ethnic Foods',
            'Ready-to-Eat Meals',
            'Organic and Health - Focused Foods',
            'Others'
        ]
    },
   
]
export const cat2 = [
  
   {
       name:'Computers & Laptops',
       items:[
        'Desktop Computers',
        'Laptops',
        'All-in-One PCs',
        'Mini PCs',
        'Mini PCs',
        'Workstations'
       ]
   },
   {
       name:'Computer Components',
       items:[
           'Processors(CPUs)',
            'Motherboards',
            'RAM(Memory)',
            'Graphics Cards(GPUs)',
            'Storage Devices(HDD, SSD, NVMe)',
            'Power Supply Units(PSU)',
            'Cooling Systems(Fans, Liquid Cooling)',
            'Computer Cases',
       ]
   },
   {
       name:'Computer Accessories & Peripherals',
       items:[
           'Keyboards',
           ' Mice',
            'Mouse Pads',
            'External Hard Drive',
            'USB Flash Drives',
            'Memory Cards',
            'External DVD',
            'Docking Stations',
            'Hubs',
            'Cooling Pads',
            'Adapters',
             'Converters',
            'Stands',
       ]
   },
   {
       name:'Monitors & Displays',
       items:[
            'LED & LCD Monitors',
            'Gaming Monitors',
            'Ultra-Wide & 4K Monitors',
            'Portable Monitors',
            'Touchscreen Monitors',
       ]
   },
   {
       name:'Networking Devices',
       items:[
            'Routers & Modems',
            'Wi-Fi Range Extenders',
            'Network Switches',
            'Access Points',
            'Network Cables',
            'Network Adapters',
       ]
   },
   {
       name:'Office Equipment',
       items:[
            'Inkjet Printers',
            'Laser Printers',
            'All-in-One Printers',
            '3D Printers',
            'Scanners',
            'Laminators',
            'Binding Machines',
            'Label Printers',
       ]
   },
   {
       name:'Storage & Data Devices',
       items:[
           'External Hard Drives',
            'Internal Hard Drives',
            'Solid State Drives(SSDs)',
            'Network Attached Storage(NAS)',
            'Cloud Storage Subscriptions',
       ]
   },
   {
       name:'Smart Gadgets & Wearables',
       items:[
          ' Smartwatches',
           ' Fitness Bands',
            'Smart Glasses',
            'VR Headsets',
            'Smart Pens',
       ]
   },
   {
       name:'Gaming & Entertainment',
       items:[
          'Gaming Consoles',
            'Gaming Controllers',
           'VR Headsets',
           'Gaming Chairs',
            'Gaming Desks',
       ]
   },
   {
       name:'Audio & Sound Devices',
       items:[
            'Headphones',
            'Earphones',
            'Bluetooth Speakers',
            'Soundbars',
            'Home Theater Systems',
            'Microphones',
            'Audio Interfaces',
       ]
   },
   {
       name:'Power & Backup Solutions',
       items:[
            'Power Supplies(UPS)',
            'Power Banks',
            'Surge Protectors',
            'Laptop Chargers',
            'Rechargeable Batteries',
       ]
   },
   {
       name:'Software & Security',
       items:[
           'Operating Systems',
            'Antivirus',
            'Productivity Software',
            'Graphic Software',
            'Cloud & Backup Solutions',
       ]
   },
   {
       name:'Cables & Connectors',
       items:[
           'HDMI Cables',
            'USB Cables',
            'VGA Cables',
            'DisplayPort Cables',
            'Ethernet Cables',
            'Audio Cables',
       ]
   }
]

const StockForm = (props: any) => {
    let { stock: s } = props;

    let { item, state } = useReactiveVar(tableState)

    const [stock, setStock] = useState(initProduct);
    const [selectedFile, setSelectedFile] = useState("");
    const [focused, setFocused] = useState('')
    const [dropdown, setDropDown] = useState('')
    const [cancel, setCancel] = useState('')
    const [mail, setMail] = useState('')
    const [next, setNext] = useState(false)

    useEffect(() => {
        let i = item?._id ? {
            ...item,
            expiry: item?.expiry && item?.expiry.length > 5 ? simplifyExpDate(item?.expiry) : item?.expiry,
        } : initProduct;
        setStock(i)
    }, [item])

    useEffect(() => {
        
            const account:any = getLocalStore()
            if (account) {
                // console.log(`mail:${JSON.stringify(account, null, 2)}`)
                setMail(account.email)
            }
        
    }, []);

    const queries = useReactiveVar(queryOptions)

    const units = [
        'l',         // Liter
        'cl',        // Centiliter
        'ml',        // Milliliter
        'g',         // Gram
        'mg',        // Milligram
        'kg',        // Kilogram
        'm',         // Meter
        'cm',        // Centimeter
        'mm',        // Millimeter
        'ft',        // Foot
        'in',        // Inch
        'oz',        // Ounce
        'lb',        // Pound
        't',         // Ton
        'µg',        // Microgram
        'nm',        // Nanometer
        'km',        // Kilometer
        'yd',        // Yard
        'sq m',      // Square Meter
        'sq ft',     // Square Foot
        'cu m',      // Cubic Meter
        'cu ft',     // Cubic Foot
        'dl',        // Deciliter
        'mmHg',      // Millimeters of mercury (pressure)
        'mol',       // Mole
        'kcal',      // Kilocalorie
    ];

    const quantities = [
        'packs',
        'pcs',        // Pieces
        'cards',
        'packets',
        'cartons',
        'bottles',
        'crests',
        'pairs',
        'yards',
        'tins',
        'cans',
        'bags',
        'sachets',
        'oz',         // Ounces
        'plates',
        'boxes',
        'cups',
        'wraps',
        'sacks',
        'mudus',      // Traditional measure
        'rolls',
        'barrels',
        'containers',
        'dozens',
        'reams',
        'trays',
        'sticks',
        'sheets',
        'pouches',
        'jars',
        'tubes',
        'bundles',
        'units',
        'strips',
        'blocks',
        'kg bags',
        'bales',
        'palettes',
        'grams',
    ];

    const forms = [
        'Tablet',
        'Syrup',
        'Solution',
        'Ointment',
        'Spray',
        'Gel',
        'Cream',
        'Powder',
        'Injection',
        'Grain',
        'Flour',
        'Liquid',
        'Capsule',
        'Caplet',
        'Lozenge',
        'Drops',
        'Suppository',
        'Patch',
        'Emulsion',
        'Suspension',
        'Inhaler',
        'Elixir',
        'Foam',
        'Paste',
        'Lotion',
        'Granules',
        'Pellets',
        'Troche',
        'Chewable Tablet',
        'Transdermal Patch',
        'Effervescent Tablet',
        'Subcutaneous Injection',
        'Buccal Tablet',
        'Film-Coated Tablet',
        'Extended-Release Capsule',
        'Topical Solution',
        'Nasal Spray',
        'Rectal Suppository',
        'Vaginal Suppository'
    ];


    const re = /^[0-9\b]+$/;

    const handleChange = (e: any) => {
        e.persist();
        const { target: { name, value } } = e
        if (name === "expiry") {
            if ((re.test(value) || value.includes("/") || value === "") && +value.length <= 5) {
                setStock({
                    ...stock,
                    [name]: value,
                });
            }
        }
        setStock({
            ...stock,
            [name]: name === 'warningCount' || name === 'expiryWarning' || name === 'costPrice' || name === 'sellingPrice' ? +value : value,
        });
    };

    const convertStringToDate = (date: string) => {
        let d = date.split("/");
        return new Date(`20${d[1]}-${d[0]}-01`);
    };

    const handleClear = (name: string) => {
        setStock({
            ...stock,
            [name]: "",
        });
    };

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }
    const handleSelection = (key: string, val: string) => {
        setStock({
            ...stock,
            [key]: val,
        });
        setFocused('')
    }
    const handleCategorySelection = (name: string, category: string) => {
        setStock({
            ...stock,
            categories: {
                name,
                category,
            }
        });
        setFocused('')
    }

    const handleQChange = (e: any) => {
        const { target: { value } } = e
        setStock({
            ...stock,
            q: {
                ...stock.q,
                val: +value
            }
        });
        setFocused('')
    }
    const handleQSelection = (unit: string) => {
        setStock({
            ...stock,
            q: {
                ...stock.q,
                unit,
            }
        });
        setFocused('')
    }

    const handleSizeChange = (e: any) => {
        const { target: { value } } = e
        setStock({
            ...stock,
            size: {
                ...stock.size,
                val: value
            }
        });
        setFocused('')
    }

    const handleSizeUnitSelection = (unit: string) => {
        setStock({
            ...stock,
            size: {
                ...stock.size,
                unit,
            }
        });
        setFocused('')
    }

    const handleFormSelection = (form: string) => {
        setStock({
            ...stock,
            form: form
        });
        setFocused('')
    }



    const getProfit = () => stock.sellingPrice - stock.costPrice || 0;
    const getProfitMargin = () => (Math.round((getProfit() / stock.sellingPrice) * 100)) || ''

    const [submit, { loading, error: e, data }] = useMutation(SAVE_PRODUCT, {
        update: (cache, { data: { saveProduct: newProduct } }) => {
            const data: any = cache.readQuery({
                query: GET_PRODUCTS,
                variables: {
                    ...queries
                }
            });
            cache.writeQuery({
                query: GET_PRODUCTS,
                data: {
                    products: updateProdCache(data?.products, newProduct, queries.group),
                },
            });
            // showFeedBack();
        },
    });

    if (e) {
        console.log({ ...e })
    }
    const [uploadFile, { loading: uploading, error: uploadError }] = useMutation(UPLOAD_FILE, {


        onCompleted: (data: any) => {
            setStock({
                ...stock,
                mediaUrls: [data.uploadFile.uri, ...stock.mediaUrls]
            });
        },
        onError: (error: any) => {
            console.log({ ...error });
        },
    });

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        } else {
            setSelectedFile(file);
            uploadFile({
                variables: {
                    file,
                },
            });
        }
    };

    if (uploadError) {
        console.log(uploadError);
    }

    const closeDetails = () => {
        tableState({
            state: '',
            item: null
        })
        showInventoryModal(false)
    }

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!next) {
            setNext(true)
        } else {

       
        let newProduct: Product = stock;
        if (stock._id) {
            const { __typename, q, size, categories, added, modified, ...s } = stock;
            newProduct = {
                ...s,
                q: stripTypename(q),
                size: stripTypename(size),
                added: stripTypename(added),
                modified: stripTypename(modified),
                categories: stripTypename(categories),
            };
        } else {

        }
        const { expiry } = newProduct;

        submit({
            variables: {
                product: {
                    ...newProduct,
                    expiry: expiry ? convertStringToDate(expiry) : expiry,
                },
            },
        });
        }
    };
    return (
        <FormContainer show={state === 'editing'}>
            <Form
            noValidate={true}
            {...props}
            id={stock?._id}
            onClick={(e: Event) => e.stopPropagation()}
            onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
        >
            <HeaderCont>
                <h6>{stock._id ? "Update":"New"} product</h6>
                               {/* <SaveButton
                    data={data}
                    loading={loading}
                    title={'Save'}
                    disabled={
                        !stock.name ||
                        !stock.description ||
                        // !stock.q.val ||
                        !stock.sellingPrice ||
                        !stock.categories.name ||
                        !stock.categories.category
                    }
                /> */}
                    <CloseBtn title="Close" onClick={closeDetails}>
                        <Icon>
                            <TimesIcn size={8} color='black' />
                        </Icon>
                    </CloseBtn>
            </HeaderCont>
            <BodyCont>
                    <Sec1 in={next}>
                        <Column>
                            <h6>Product</h6>
                            <FormGroupCont className="fgroup">
                                <FormGroup onMouseLeave={() => mouseLeave('name')} onMouseEnter={() => mouseEnter('name')} top>
                                    <Input
                                        required
                                        name="name"
                                        value={stock.name}
                                        label='Name'
                                        placeholder='Product name'
                                        focused={focused === 'name'}
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'name') &&
                                        <Icon onClick={() => handleClear('name')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                                <FormGroup
                                    onMouseLeave={() => mouseLeave('description')}
                                    onMouseEnter={() => mouseEnter('description')}>
                                    <Input
                                        required
                                        name='description'
                                        label='Description'
                                        value={stock.description}
                                        placeholder='Emzor for child'
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'description') &&
                                        <Icon onClick={() => handleClear('description')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                            </FormGroupCont>
                        </Column>

                        <Column>
                            <h6>Inventory</h6>
                            <FormGroupCont className="fgroup">
                                <FormGroup onMouseLeave={() => mouseLeave('category')} onMouseEnter={() => mouseEnter('category')}>
                                    <DropDown
                                        required
                                        name='category'
                                        label='Category'
                                        value={stock.categories?.category}
                                        onClick={() => focus('category')}
                                    />
                                    {
                                        (cancel === 'category') &&
                                        <Icon id="cat" onClick={() => handleClear('category')}>
                                            <Chevron />
                                        </Icon>
                                    }
                                    {
                                        focused === 'category' &&
                                        <DropDownList
                                            h={292}
                                            name="category"
                                            header='Category'
                                            options={mail === 'abelov@gmail.com' ? cat2 : categories}
                                            selectCallback={handleCategorySelection}
                                            closeCallback={() => setFocused('')}
                                        />
                                    }
                                </FormGroup>
                            </FormGroupCont>
                            <FormGroupCont>
                                <Double>
                                    <FormGroup top left w={50} onMouseLeave={() => mouseLeave('size')} onMouseEnter={() => mouseEnter('size')}>
                                        <Input
                                            name='size'
                                            label='Size'
                                            placeholder='size/weight'
                                            value={stock.size?.val || ''}
                                            focused={focused === 'size'}
                                            changeCallback={handleSizeChange}
                                            focusedCallback={(name: string) => focus(name)}
                                        />
                                        {
                                            (cancel === 'size') &&
                                            <Icon >
                                                <Cancel />
                                            </Icon>
                                        }
                                    </FormGroup>
                                    <FormGroup top w={50}
                                        onMouseLeave={() => mouseLeave('unit')}
                                        onMouseEnter={() => mouseEnter('unit')}
                                    >
                                        <DropDown
                                            name='unit'
                                            label='Unit'
                                            value={stock.size?.unit || ''}
                                            onClick={() => focus('unit')}
                                        />
                                        {
                                            (cancel === 'unit') &&
                                            <Icon id="cat" onClick={() => handleClear('unit')}>
                                                <Chevron />
                                            </Icon>
                                        }
                                        {
                                            focused === 'unit' &&
                                            <SingleDropDownList
                                                h={300}
                                                name="unit"
                                                header='Units'
                                                options={units}
                                                selectCallback={handleSizeUnitSelection}
                                                closeCallback={() => setFocused('')}
                                            />
                                        }
                                    </FormGroup>
                                </Double>
                                <Double>
                                    <FormGroup w={100} onMouseLeave={() => mouseLeave('form')} onMouseEnter={() => mouseEnter('form')}>
                                        <DropDown
                                            name='form'
                                            label='Form'
                                            value={stock?.form}
                                            onClick={() => focus('form')}
                                        />
                                        {
                                            (cancel === 'forem') &&
                                            <Icon id="cat" onClick={() => handleClear('form')}>
                                                <Chevron />
                                            </Icon>
                                        }
                                        {
                                            focused === 'form' &&
                                            <SingleDropDownList
                                                h={300}
                                                name="form"
                                                header='Form'
                                                options={forms}
                                                selectCallback={handleFormSelection}
                                                closeCallback={() => setFocused('')}
                                            />
                                        }
                                    </FormGroup>
                                </Double>
                            </FormGroupCont>
                            <FormGroupCont className="fgroup">
                                <Double>
                                    <FormGroup top left off w={50} onMouseLeave={() => mouseLeave('q')} onMouseEnter={() => mouseEnter('q')}>
                                        <Input
                                            required
                                            name='q'
                                            type="number"
                                            label='Quantity'
                                            placeholder='20'
                                            value={stock?.q?.val || null}
                                            focused={focused === 'q'}
                                            changeCallback={(e: any) => handleQChange(e)}
                                            focusedCallback={(name: string) => focus(name)}
                                        />
                                        {
                                            (cancel === 'q') &&
                                            <Icon >
                                                <Drop />
                                            </Icon>
                                        }
                                    </FormGroup>
                                    <FormGroup right w={50} onMouseLeave={() => mouseLeave('warningCount')} onMouseEnter={() => mouseEnter('warningCount')}>
                                        <DropDown
                                            name='mesure'
                                            label='Mesure'
                                            value={stock?.q?.unit}
                                            onClick={() => focus('mesure')}
                                        />
                                        {
                                            (cancel === 'mesure') &&
                                            <Icon id="cat" onClick={() => handleClear('mesure')}>
                                                <Chevron />
                                            </Icon>
                                        }
                                        {
                                            focused === 'mesure' &&
                                            <SingleDropDownList
                                                name="mesure"
                                                h={300}
                                                header='Mesure'
                                                options={quantities}
                                                selectCallback={handleQSelection}
                                                closeCallback={() => setFocused('')}
                                            />
                                        }
                                    </FormGroup>
                                </Double>
                            </FormGroupCont>
                        </Column>


                    </Sec1>
                    <Sec2 in={next}>
                        <Column>
                            <h6>Pricing</h6>
                            <FormGroupCont>
                                <Double>
                                    <FormGroup top left w={50} onMouseLeave={() => mouseLeave('costPrice')} onMouseEnter={() => mouseEnter('costPrice')}>
                                        <Input
                                            name='costPrice'
                                            type="number"
                                            label='Cost price'
                                            placeholder='Unit cost price'
                                            value={stock.costPrice || ''}
                                            focused={focused === 'costPrice'}
                                            changeCallback={(e: any) => handleChange(e)}
                                            focusedCallback={(name: string) => focus(name)}
                                        />
                                        {
                                            (cancel === 'costPrice') &&
                                            <Icon >
                                                <Drop />
                                            </Icon>
                                        }
                                    </FormGroup>
                                    <FormGroup top w={50}
                                        onMouseLeave={() => mouseLeave('sellingPrice')}
                                        onMouseEnter={() => mouseEnter('sellingPrice')}
                                    >
                                        <Input
                                            required
                                            name='sellingPrice'
                                            type="number"
                                            focused={focused === 'sellingPrice'}
                                            label='Selling price'
                                            placeholder='3400'
                                            value={stock.sellingPrice || ''}
                                            changeCallback={(e: any) => handleChange(e)}
                                            focusedCallback={(name: string) => focus(name)}
                                        />
                                        {
                                            (cancel === 'sellingPrice') &&
                                            <Icon >
                                                <Drop />
                                            </Icon>
                                        }
                                    </FormGroup>
                                </Double>
                                <Double>
                                    <FormGroup off top left w={50}>
                                        <Input
                                            name='profit'
                                            label='Profit'
                                            placeholder='27'
                                            value={formatMoney(getProfit()) || ''}
                                            focused={focused === 'profit'}
                                            focusedCallback={(name: string) => focus(name)}
                                        />

                                    </FormGroup>
                                    <FormGroup right w={50} onMouseLeave={() => mouseLeave('margin')} onMouseEnter={() => mouseEnter('margin')}>
                                        <Input
                                            value={`${getProfitMargin() ?? ''}${getProfitMargin() ? '%' : ''}`}
                                            name='margin'
                                            label='Margin'
                                            placeholder='Profit margin'
                                            focused={focused === 'margin'}
                                            focusedCallback={(name: string) => focus(name)}
                                        />
                                    </FormGroup>
                                </Double>
                            </FormGroupCont>
                        </Column> 
                        <Column>
                    <h6>Tracking</h6>
                    <FormGroupCont className="fgroup">
                        <Double>
                            <FormGroup top left w={50}
                                onMouseLeave={() => mouseLeave('expiry')}
                                onMouseEnter={() => mouseEnter('expiry')}>
                                <Input
                                    value={stock.expiry || ''}
                                    name='expiry'
                                    focused={focused === 'expiry'}
                                    label='Expiry'
                                    placeholder='17/24'
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'expiry') &&
                                    <Icon >
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                            <FormGroup
                                top
                                right w={50}
                                onMouseLeave={() => mouseLeave('expiryWarning')}
                                onMouseEnter={() => mouseEnter('expiryWarning')}
                            >
                                <Input
                                    value={stock.expiryWarning || ''}
                                    name='expiryWarning'
                                    type='number'
                                    label='Expiry warning'
                                    focused={focused === 'expiryWarning'}
                                    placeholder='10'
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'expiryWarning') &&
                                    <Icon>
                                        <Drop />
                                    </Icon>
                                }
                            </FormGroup>
                        </Double>
                        <FormGroup onMouseLeave={() => mouseLeave('warningCount')} onMouseEnter={() => mouseEnter('warningCount')}>
                            <Input
                                name='warningCount'
                                label='Stock level'
                                placeholder='27'
                                type='number'
                                value={stock.warningCount || ''}
                                focused={focused === 'warningCount'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                        </FormGroup>
                    </FormGroupCont>
                </Column>
                        <Column style={{ height: '100%' }}>
                    <h6>Media</h6>
                        <FormGroupCont className="fgroup">
                            <ImageGroup>
                                <ImageCont>
                                    {
                                        stock.mediaUrls[0] && <img src={getImageUrl(stock.mediaUrls[0])} alt="" />
                                    }
                                    <FileInput onChange={handleFileChange} />
                                    {
                                        uploading ?
                                            <Loader />
                                            :
                                            <Mask>Edit</Mask>
                                    }
                                </ImageCont>
                                <FileInput onChange={handleFileChange} />
                                <p>Add image</p>
                            </ImageGroup>
                        </FormGroupCont>
                </Column> 
                </Sec2>
                    <BtnCont>
                        {
                            next ?
                            <Button
                                data={data}
                                loading={loading}
                                title='Save'
                            // disabled={
                            //     !stock.name ||
                            //     !stock.sellingPrice ||
                            //     !stock.categories.name ||
                            //     !stock.categories.category
                            // }
                            />
                            :
                            <NextBtn>Next</NextBtn>
                        } 
                    </BtnCont>
                   
                    { next && <BackLink onClick={() => setNext(false)}>Back</BackLink>}
                    
                </BodyCont>
                
            
                
            </Form>
        </FormContainer>

    )
}
export default StockForm