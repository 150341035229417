import { Link } from "react-router-dom"
import { HeaderMainCont } from "../table/styles"
import { HeaderCont, TabLink, TabLinks, TabLinksCont } from "./styles"
import { useLocation } from "react-router-dom"
import { useState } from "react"

const LinksHeader = (props: any) => {
    const { pathname } = useLocation()
    const GROUP_OPTION = [
        'DAYS',
        'MONTHS',
        'YEARS',
        'CUSTOMER'
    ]

    const [active, setActive] = useState('')

    const isPathTo = (name: string) => pathname.includes(name)

    return (
        <HeaderMainCont>
            <HeaderCont>
                <TabLinksCont>
                    <TabLinks>
                        <TabLink active={!isPathTo('repo')} >
                            <Link to='/'>My store</Link>
                        </TabLink>
                        <TabLink active={isPathTo('repo')}>
                            <Link to='repo' >Global store</Link>
                        </TabLink>
                    </TabLinks>
                </TabLinksCont>
            </HeaderCont>
        </HeaderMainCont>
    )
}

export default LinksHeader