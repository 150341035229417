import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../apollo'
import { Desc, DescCol, DescCont, MenuOptionsList, Amount, Balance, Title, Option, TitleCont, PayoutCont, ContactCont } from './styles'
import { ImageItem } from './image'
import { Divider, Icon } from '../../icons/styles'
import { Ellipsis } from '../../icons'
import { showCashBookModal, showInventoryModal, tableState } from '../../../globals'
import { GET_PAYOUTS, GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { useMutation } from '@apollo/client'
import { DELETE_PAYOUT, DELETE_PRODUCT } from '../../../graphql/mutations'
import { formatMoney } from '../../../utils'

const PayoutItem = (props: any) => {
    const {payout, count, index}= props
    const [showMenu, setShowMenu] = useState<any>(false)

    
    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item:payout,
        })
        setShowMenu(false)
        showCashBookModal(true)
    }

    const [deletePayOut, { error, loading }] = useMutation(DELETE_PAYOUT, {
       
        update: (cache, { data: { deletePayout: deleted } }) => {
            const cached: any = cache.readQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queryOptions()
                }
            })

            console.log(JSON.stringify(cached, null, 2))

            let newPayOut = cached.payouts.map((po: any) => {
                return (po.records.some((p: any) => p._id === deleted._id)) ?
                    ({
                        ...po,
                        totalBal: po.totalBal - deleted.paid,
                        records: po.records.filter((p: any) => p._id !== deleted._id)
                    })
                    :
                    po
            })
           
            cache.writeQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    payouts: newPayOut.filter((p: any) => p.records.length)
                }
            });
        }
    });

     if (error) console.log({ error })

    const handelDeletePayout = ( e: any) => {
        e.stopPropagation();
        deletePayOut({
            variables: {
                id: payout._id
            },
       } )
       }
       
    
  return (
      <PayoutCont>
          <ImageItem name={payout?.supplier?.name} />
          <DescCol>
              <DescCont>
                  <TitleCont>
                      <Title>{payout?.supplier.name}</Title>
                      <Icon onClick={(e: SyntheticEvent) => setShowMenu(true)} rot={90} >
                          <Ellipsis />
                      </Icon>
                      <MenuOptionsList show={showMenu}>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e,'repayment')}>
                              <p>Make payment</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e,'update')}>
                              <p>Edit</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e, 'details')} >
                              <p>Details</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => handelDeletePayout(e) }>
                              <p style={{ color: 'red' }}>Delete invoice</p>
                          </Option>
                      </MenuOptionsList>
                  </TitleCont>
                  <ContactCont>
                      <Amount>{formatMoney(payout?.amount)}</Amount>
                      <Balance>{formatMoney(payout?.amount - payout.paid)}</Balance>
                  </ContactCont>
                  {/* <Desc>Emzor Co.</Desc> */}
              </DescCont>
              {/* <ContactCont>
                  <Phone>08022778054</Phone>
                  <Mail>aminu@example.com</Mail>
              </ContactCont> */}
              {
                  index + 1 !== count && <Divider bottom={-12} />
              }
          </DescCol>
      </PayoutCont>
  )
}

export default PayoutItem