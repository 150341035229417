
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, CardCont
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import ExpenseListItem from './item'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import SuplierItem from './item'

type Props = {}

function SupplierCard(props: any) {

    const { list: suppliers, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)
    
    return (
        <CardCont>
             <Header>
                {(group === 'date added' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}
            </Header> 
            <Card>
                {
                    suppliers.map((supplier: any, index: number) => (
                        <SuplierItem count={count} index={index} supplier={supplier} key={supplier._id} />

                     ))
                }
            </Card>
        </CardCont>
       
    )
}

export default SupplierCard