import styled from "styled-components";

export const ButtonItem = styled.button.attrs(props => ({
  placeholder: props.disabled,
}))<any>`
  height: 45px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  display: flex;
  margin-top: 0px;
  bottom: 0;
  right: 0;
  z-index:150;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 13px;
  color: ${props => props.disabled ? '#4f4f4f' : 'white'} ;
  font-weight: 600;
  background-color: ${props => props.disabled ? 'whitesmoke' : '#232323'};
  &:hover {
    border: none;
    color: ${props => props.disabled ? '#b2b3b2' : 'white'};
    background-color: ${props => props.disabled ? '#646363' : '#010c07'} ;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
  @media(max-width: 768px) {
    .check {
      svg {
        stroke: #25ec78;
      }
    }
  }
`;

export const SaveBtnItem = styled(ButtonItem)`
  height: 40px;
    width: 69px;
    display: none;
    border-radius: 20px;
    margin-top: 0px;
     @media(max-width: 768px) {
      display: flex;
     }
`
export const Container = styled.button<any>`
  height: 55px;
  width: 55px;
  border-radius: 50%;
  position: fixed;
  display: flex;
  bottom: 15%;
  right: ${props=>props.show ? 10: -50}%;
  transition: all .2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index:10;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #25ec78;
  font-size: 11px;
  color: #424242;
  font-weight: 600;
  &:hover {
    border: none;
    background-color: #08d57f;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .icon {
    width: 18px;
    height: 18px;
     svg {
      stroke: "white";
    }
  }


`;
export const AddButtonCont = styled(Container)`
  width: 55px;
  height: 55px;
  bottom: 10%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  @media(max-width: 768px) {
    display: flex;
  }
`
export const CartBtnCont = styled.button<any>`
  height: 35px;
  width: 35px;
  border-radius: 30px;
  position: relative;
  display: flex;
  z-index:150;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #ffffff;
  font-size: 11px;
  color: #424242;
  font-weight: 600;
  svg {
    stroke: grey;
  }
  &:hover {
    border: none;
    background-color: #08d58051;
  }
  &:focus {
    border: none;
  }
  .icon {
    width: 18px;
    height: 18px;
    
  }
`;
export const IconBtn = styled.button<any>`
  height: 25px;
  width: 25px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  z-index: 1000;
  background-color: white;
  font-size: 14px;
  
  .icon {
    width: 24px;
    height: 24px;
    p {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
    }
   
  }
  &:hover {
    border: none;
    background-color: #1b1a1a19;
    .icon {
      svg {
        stroke: #40c764;

      }
    }
  }
  &:focus {
    border: none;
  }
`;
export const CancelBtn = styled(IconBtn)`
  border-radius: 50%;
  background-color: #f9b491de;
  font-size: 14px;
  position: absolute;
  top: 105%;
  .icon {
    width: 12px !important;
    height: 12px !important;
    svg {
      stroke: #f46d6d;
      fill:#808080;
    }
  }
  &:hover {
    border: none;
    background-color: #f9b491de;
    .icon {
      svg {
         stroke:#f46d6d;
      }
    }
  }
`

export const MessageButton = styled.button<any>`
  height: 45px;
  width: 95px;
  border-radius: 30px;
  position: relative;
  display: flex;
  bottom: -10px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #262726;
  font-size: 14px;
  color: white;
  font-weight: 600;
  &:hover {
    border: none;
    background-color: #131514;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .icon {
    width: 12px;
    height: 12px;
  }
`;

export const Counter = styled.div<any>`
  height: 20px;
  min-width: 20px;
  padding: 0px 2px;
  color: ${props => props.theme.dark.colors.labels.pri
  };
  border-radius: 50%;
  position: absolute;
  top:-2px;
  font-size: 11px;
  border:none;
  right:-4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  z-index: 50;
  background-color:  #f8580e;
`
export const CheckOutBtnItem = styled.button<any>`
  height: 45px;
  width: 100%;
  margin-top: 40px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
   color: #ffffff;
   background-color: 'black';
  &:hover {
    border: none;
    background-color: '#111211';
    color: white;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
`;