import { useMutation, useReactiveVar } from "@apollo/client"
import { SyntheticEvent, useEffect, useState } from "react"
import { Cancel, Chevron, TimesIcn } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { showCashBookModal, tableState } from "../../../../../globals"

import { initPayout, queryOptions } from "../../../../../types/defaults"
import { SupplierModel } from "../../../../../types/model"
import { formatMoney, stripTypename } from "../../../../../utils"
import { updatePayOutCache } from "../../../../../utils/caches"
// import Button, { SaveButton } from "../../buttons"
import { Input } from "../inputs"
import { BtnCont, Column, ColumnCont, FeedBackMsg, Form, FormContainer, FormGroup, FormGroupCont, HeaderCont, InfoCont } from "./styles"


import { CloseBtn } from "../../buttons/styles"
import DropDown from "./dropcont"
import { DropDownList } from "./dropdown"
import { GET_PAYOUTS } from "../../../../../graphql/queries"
import { SAVE_PAYOUT } from "../../../../../graphql/mutations"
import Button from "../../buttons"

 
 const methods = [
    "Cash",
    "POS",
    "Transfer",
 ]
const RepaymentForm = (props: any) => {

    const { state, item } = useReactiveVar(tableState)

    const queries = useReactiveVar(queryOptions)

    const [payOut, setPayout] = useState(initPayout);
    const [payment, setPayment] = useState(0);

    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    useEffect(() => {
        setPayout(item || initPayout)
        setPayment(item?.amount -  item?.paid)
    }, [item, state])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    const [savePayout, { error, loading, data }] = useMutation(SAVE_PAYOUT, {
        update: (cache, { data: { savePayout: newPayout } }) => {
            const prevPayout: any = cache.readQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queries,
                    group: 'date'
                }
            });
            console.log(`new: ${newPayout}`)
            cache.writeQuery({
                query: GET_PAYOUTS,
                data: {
                    payouts: updatePayOutCache(prevPayout?.payouts, newPayout, payment, queries.group),
                },
            });
            setPayout({...payOut, paid: payOut.paid+payment})
        }
    });

    if (error) {
        console.log({ ...error })
    }

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }

    const handleClear = (name: string) => {
        setPayout({
            ...payOut,
            [name]: ''
        })
    }

    const closeForm = (e: SyntheticEvent) => {
        e.preventDefault()
        tableState({
            state: '',
            item: null
        })
        showCashBookModal(false)
    }

    const handleChange = (e: any) => {
        e.persist();
        const { target: { name, value } } = e
        setPayment(+value)
    }
   
    const handleSelection = (method: any) => {
        setPayout({
            ...payOut,
            method
        });
        setFocused('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        let po = stripTypename(payOut);
        po = {
            ...po,
            supplier: stripTypename(po.supplier)
        }
        savePayout({
            variables: {
                payout: {
                     ...po,
                     paid: state === 'repayment' ? po.paid + payment : po.paid,
                },
                mode: state
            }
        })
    }


    return (
        <FormContainer show={state === 'repayment' || state === 'update'}>
            {(state === 'repayment' || state === 'update') && 
                <Form
                    {...props}
                    noValidate={true}
                    id={payOut?._id}
                    onClick={(e: Event) => e.stopPropagation()}
                    onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
                >
                    <FeedBackMsg show={payment > (payOut?.amount - payOut?.paid) }> <p>Payment can not be greater than balance</p></FeedBackMsg>
                    <HeaderCont>
                        <h6>Payment</h6>
                        {state === 'update' && <p>Editing</p>}
                        <CloseBtn title="Close" onClick={(e: any) => closeForm(e)}>
                            <Icon>
                                <TimesIcn size={8} color='black' />
                            </Icon>
                        </CloseBtn>
                    </HeaderCont>
                    <ColumnCont>
                        <InfoCont>
                            <p>Current Balance</p>
                            <h4>N{formatMoney(payOut?.amount - payOut?.paid)}</h4>
                            <p>{payOut.supplier.name}</p>
                        </InfoCont>
                        <Column>
                            {/* <h6>Payment</h6> */}
                            <FormGroupCont className="fgroup">
                                <FormGroup
                                    onMouseLeave={() => mouseLeave('amount')}
                                    onMouseEnter={() => mouseEnter('amount')} top>
                                    <Input
                                        required
                                        name='amount'
                                        type='number'
                                        label='Amount'
                                        value={payment || null}
                                        focused={focused === 'amount'}
                                        placeholder='Specify amount'
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                    {
                                        (cancel === 'amount') &&
                                        <Icon onClick={() => handleClear('amount')}>
                                            <Cancel />
                                        </Icon>
                                    }
                                </FormGroup>
                                <FormGroup onMouseLeave={() => mouseLeave('method')} onMouseEnter={() => mouseEnter('method')}>
                                    <DropDown
                                        name='method'
                                        label='Method'
                                        value={payOut?.method}
                                        onClick={() => focus('method')}
                                    />
                                    <Icon id="cat" onClick={() => handleClear('method')}>
                                        <Chevron />
                                    </Icon>
                                    {
                                        focused === 'method' &&
                                        <DropDownList
                                            mh={90}
                                            name="method"
                                            header='Suppliers'
                                            options={methods}
                                            selectCallback={handleSelection}
                                            closeCallback={() => setFocused('')}
                                        />
                                    }
                                </FormGroup>
                            </FormGroupCont>
                        </Column>
                    </ColumnCont>
                    <BtnCont>
                        <Button
                            data={data}
                            loading={loading}
                            title='Pay'
                            disabled={payment > (payOut?.amount - payOut?.paid)}
                        />
                    </BtnCont>
                   
                </Form>
            }
            
        </FormContainer>
    )
}
export default RepaymentForm