import React from 'react'
import { Footer, Header, ItemsCont } from './styles'

import { useReactiveVar } from '@apollo/client';

import { Link } from 'react-router-dom';
import { formatFigures, format_date } from '../../../../utils';
import { queryOptions } from '../../../../types/defaults';
import { Expense } from '../expense';
import { Divider } from '../../../../components/icons/styles';
import { Expense as ExpenseModel } from '../../../../types/model';
import { Supplier } from '../suplier';
const SupplierListGroup = (props: any) => {
    let { list: suppliers, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)

    const generateLink = (group:string,id: string)=>{
        return `/suppliers?p=${group}&q=${encodeURIComponent(id)}`;
    }

    return (
        <>
            <Header>
                <h6>{(group === 'date added' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}</h6>
                {
                    count-10 > 0 && 
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                }
                <Divider />
            </Header>
            {
                suppliers.map((supplier: any) => (
                    <ItemsCont >
                        <Supplier supplier={supplier} key={supplier._id} {...props}  />
                    </ItemsCont>
                    )
                )
            }
            <Footer id="group--footer">
                {/* <p>+{count - 10 <= 0 ? 0 : count - 10}</p> */}
            </Footer>
        </>
    )
}

export default SupplierListGroup