import { Fragment, ReactElement, useState } from "react";
import styled from "styled-components";
import { useMutation, useReactiveVar } from "@apollo/client";

import { HistoryItems, HistoryCont, PropItem} from "./styles";
import { ImageItem } from "../forms/share/imageView";
import { Divider, Icon } from "../../../../components/icons/styles";
import { More } from "../../../../components/icons";
import { DELETE_EXPENSE } from "../../../../graphql/mutations/expense";
import { GET_EXPENSES } from "../../../../graphql/queries/expenses";
import { formatMoney } from "../../../../utils";
import { MoreActions } from "../listMenu";
import { queryOptions } from "../../../../types/defaults";
import { showInventoryModal, tableState } from "../../../../globals";
// import { roundAmount } from "../charts/header";
// import { DELETE_EXPENSE } from "../../graphql/mutations";



export function History(props: any): ReactElement {

    const [menuOpened, reSetMenu] = useState(false);

    const { expense, editCallback } = props;

    // const { group, query } = useReactiveVar(expenseCriteria)
    const togleMenu = () => {
        reSetMenu(!menuOpened)
    }
    const closeMenu = () => {
        reSetMenu(false)
    }

    let [deleteExpenses, { error, loading }] = useMutation(DELETE_EXPENSE, {
        update: (cache, { data }) => {
            const existingExpenses: any = cache.readQuery({
                query: GET_EXPENSES,
                variables: {
                    ...queryOptions()
                }
            })
            let newExpenses = existingExpenses.expenses.map((expenseGroup: any) => {
                return (expenseGroup.records.some((expenseItem: any) => expenseItem._id === data.deleteExpense._id)) ?
                    ({
                        ...expenseGroup,
                        records: expenseGroup.records.filter((expense: any) => expense._id !== data.deleteExpense._id)
                    })
                    :
                    expenseGroup
            })
            cache.writeQuery({
                query: GET_EXPENSES,
                variables: {
                    ...queryOptions()
                },
                data: {
                    expenses: newExpenses.filter((expense: any) => expense.records.length)
                }
            });
        }
    })

    if (error)
        console.log({ error });

    const deleteItem = (e: any, id: string ) => {
        e.stopPropagation();
        closeMenu()
        deleteExpenses({
            variables: {
                id
            },
        })
    }

    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: expense,
        })
        showInventoryModal(true)
    }
    const displayDetails = (e: Event, stock: any) => {
        closeMenu();
        // editCallback(e, 'stocks', 'products', stock, 'edit', false)
    }
    return (
        <HistoryCont>
            <HistoryItems>
                <PropItem 
                >
                    <p>Amina Liman</p>
                    <p>Zango Cosmentics</p>
                </PropItem>
                <PropItem>
                    <p>14 Cartons of Locozets</p>
                </PropItem>
                <PropItem>
                    <p>N270,500</p>
                </PropItem>
                <PropItem>
                    <Icon onClick={togleMenu}>
                        <More />
                    </Icon>
                    <p>N240,000</p>
                </PropItem>
                <Divider />
            </HistoryItems>
            {
                menuOpened &&
                <MoreActions
                    closeMenuCallback={closeMenu}
                    actions={
                        [
                            {
                                label: 'Edit',
                                // permitted: isAdmin() || can('edit', 'expenses'),
                                callback: (e: any) => opendEditor(e, 'editing'),
                            },
                            {
                                label: 'Expense Info',
                                // permitted: true,
                                callback: (e: any) => displayDetails(e, expense) as any
                            },
                            {
                                label: 'Delete Expense',
                                // permitted: isAdmin() || can('delete', 'invoices'),
                                callback: (e: any) => deleteItem(e, expense._id)
                            }
                        ]
                    }
                />
            }
        </HistoryCont>
    )
}
