
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../../icons'
import { Icon, Divider } from '../../../icons/styles'
import { ImageItem } from '../image'
import {
    Card,
    Footer, Header, ItemsCont,
} from '../styles'
import { format_date, roundAmount } from '../../../../utils'

import Invoice from '../invoiceListItem'
import { queryOptions } from '../../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'
import ShownInvoice from './listCard'
import { useLocation } from 'react-router-dom'

type Props = {}

function ShowInvoiceCard(props: any) {

    const { list } = props;
    const location = useLocation() as any
    const params = new URLSearchParams(location.search)

    return (
        <ItemsCont>{
            <>
              <Header>
                    <h6>{format_date(params.get("q"))}</h6>
                </Header>
            <Card>
              {
                    list.map((record: any, i: number) => (
                        <ShownInvoice
                             key={record.stocks[0].item._id}
                             stock={record.stocks[0]} invoice={record}
                        />
                    ))
                }
            </Card>
            </>
        }
        </ItemsCont>
    )
}

export default ShowInvoiceCard