import React from 'react'
import { DropCont } from './styles';

const DropDown = (props: any) => {
    const { required, name, value, label } = props;
    return (
        <DropCont  {...props} >
            <label>
                {label}
                {required && <span>*</span>}
            </label>
            {
                value &&
                <div>{value}</div>
            }
        </DropCont>
    )
}

export default DropDown