
import { gql } from '@apollo/client'

export const GET_PAYOUTS = gql`
    query getPayouts( $query: String, 
        $filter: String, 
        $group: String, 
        $offset: Int) {
        payouts(query: $query,filter: $filter, group: $group, offset: $offset) {
            _id 
            records {
                _id
                supplier {
                    _id
                    name
                    phone
                }
               amount
               paid
               method
               remark
               createdAt
               updatedAt
            }
            count
            totalAmount
            totalPaid
            totalBal
        }
    }
`



