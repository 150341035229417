import { useReactiveVar } from '@apollo/client'
import React, {  } from 'react'
import { CancelBtn, Container, Counter, IconBtn } from './styles'
import { Cancel, Cart, Plus } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { cart, showCheckOutModal, showInventoryModal } from '../../../../globals'
import { initInvoice } from '../../../../globals/defaults'

const CircularButton = (props: any) => {
    const cartList = useReactiveVar(cart)
    const { stocks } = cartList;
    const clear = (e: any) => {
        e.stopPropagation()
        cart({ ...initInvoice })
    }
    return (
        <Container 
            show={stocks?.length} 
            onClick={() => showCheckOutModal(true)}> 
            {
                stocks?.length ?
                <Counter>
                    {stocks?.length}
                </Counter>
                :
                null
            }
           Add to my inventory
            <CancelBtn onClick={(e:any)=>clear(e)}>
                Cancel
            </CancelBtn>
        </Container>
    )
}

export default CircularButton