import styled from "styled-components";

export const DetContainer = styled.div.attrs({ className: 'container' }) <any>`
  height: ${(props) => (props.show ? 90 : 0)}vh;
  width: 300px;
  left: 66%;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  position: fixed;
  padding-top: 0px;
  overflow-x: hidden;
  overflow: hidden;
  z-index: 28000000;
   background: white;
  transition: all 0.2s ease-in;
  bottom: ${(props) => (props.show ? 0 : -240)}%;
  @media(max-width: 768px) {
    left: 0;
    padding: 0px;
    transition: all 0.2s ease-in;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: ${(props) => (props.show ? (props?.h + 470)  : 0)}px;
    bottom: ${(props) => (props.show ? 0 : -240)}%;
  }
`;
export const HeaderCont = styled.header`
    height: 40px;
    width: 100%;
    padding: 5px 0px;
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    justify-content: space-between;
     h6 {
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
    }
    .icon {
        height: 14px;
        width: 14px;
        cursor: pointer;
    }
    @media(max-width: 768px) {
      padding: 0px 14px;
      height: 50px;
      h6 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 600;
      }
      .icon {
        height: 16px;
        width: 16px;
        svg {
          stroke: #191a19;
        }
      }
    }
`

export const ListItem = styled.ul`
  width: 100%;
  padding: 0px;
  height: 100%;
  list-style: none;
  background: white;
  @media(max-width: 768px) {
    padding: 0px 4px;
   
  }
`
export const Item = styled.li`
  width: 100%;
  padding: 10px 0px;
  height: 35px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  p:first-child {
    color: grey;
    font-weight: normal;
  }
  @media(max-width: 768px) {
    padding: 10px;
    p {
      font-size: 13px;
      margin-bottom: 0px;
    }
    height: 40px;
  }
`
export const ListItemCont = styled.div`
  width: 100%;
  height: 60px;
  display: grid;
  gap: 0px 10px;
  overflow: visible;
  cursor: pointer;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 0px;
  grid-template-columns: 50px auto;
   @media(max-width: 768px) {
    padding: 0px 8px;
   
  }
`;
export const InfoSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 10px;
  h6 {
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 500;
    /* position: absolute; */
    background-color: white;
    /* top: -7px; */
    font-weight: 700;
  }
  `
export const ItemInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  p {
    font-size: 12px;
    margin: 0px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    color: #222222;
  }
`;
export const StoreInfo = styled(ItemInfo)`
  p {
    max-width: 140px;
  }
`;

export const DataInfo = styled.div`                                             
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 13px;
    margin: 0px;
  }
  p:last-child {
     font-weight: normal;
     text-align: right;
  }
.desc {
     color: grey;
     font-weight: normal;
  }
`;

export const Total = styled.div`
    height: 40px;
    width: 100%;
    padding: 5px 0px;
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    justify-content: space-between;
    .label {
         color: lightgrey;
         padding-left: 0px;
         font-size: 14px;
       }
    .total {
      padding: 0px 0px;
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
      text-align: right;
}
`

export const ImageCont = styled.div`
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;


export const StockImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 10px;
    width: 100%;
    height:280px;
    overflow:hidden;
    break-inside: avoid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: whitesmoke;
    @media (max-width:768px) {
    height:320px ;
    width: 100%;
    }
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        mix-blend-mode: multiply;
        filter: contrast(1);
        vertical-align: top center;
        @media (max-width: 786px) {
        object-fit: cover;
        }
    }
    p{
      color: #4f4e4e;
      font-size: 12px;
      margin-bottom: 0px;
    }
`