import styled from "styled-components";

export const Icon = styled.div.attrs<any>({
  className: "icon",
})<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  transition: all .15s ease-in;
  transform: rotate(${props => props.rot}deg);
`
export const IconBtn = styled.button.attrs<any>({
  className: "btn-itm",
})<any>`
  height: ${props=>props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  position: relative;
  background: inherit;
  &:hover {
    border: none;
    background-color: #0b0b0b1d;
  }
  &:focus {
    border: none;
  }
`;
export const IconBtn2 = styled.div.attrs<any>({
  className: "btn-itm",
})<any>`
  height: ${props=>props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  position: relative;
  background: white;
  .icon {
    width: 16px;
    height: 16px;
    stroke-width: 0.4;
    svg {
      stroke: grey;
    }
  }
`;


export const Divider = styled.div.attrs({
  className: "divider",
})<any>`
  place-items: center;
  width: ${props => props.w || 100}%;
  position: ${props => props.ps || 'absolute'};
  bottom: ${props => props.bottom || 0}%;
  left: ${props => props.l || 'auto'};
  border-bottom: 1px solid rgb(0 0 0 / 5%);
`
export const VDivider = styled.div<any>`
    height: 95%;
    top: 4%;
    border-right: 1px solid ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.separators.sec : props.theme.light.colors.separators.pri};
    position: absolute;
    left: ${props => props.lf || 100}%;
`;