import { useQuery, useReactiveVar } from '@apollo/client'
import queryString from 'query-string'
import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { LocalCheckOutModal } from '../../components/modals'
import { ChatModal } from '../../components/modals/chat'
import InvoiceModal from '../../components/modals/invoice'
import { GET_PAYMENTS } from '../../graphql/queries/invoice.query'
import { invCriteria } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import MainHeader from './component/headers/main'

import { OuterList } from './component/list/outer'
import { Container, Footer, ListGroupCont, Main, PageCont, PageCardCont, Separator, TableCont, ItemListCont } from './styles'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import PaymentCard from '../../components/cards/payment'
import SideNavigator from '../../components/sidenav'
import { formatFigures, format_date } from '../../utils'
import { Divider } from '../../components/icons/styles'
import TableHeader from './component/headersx/table'

const Payments = (props: any) => {

    const location = useLocation();
    const queries = useReactiveVar(invCriteria)

    const { search } = useLocation()

    const qs = queryString.parse(search)
    let offset = 0;

    const { data, loading, error, refetch, fetchMore, subscribeToMore } = useQuery(
        GET_PAYMENTS, {
        variables: {
            ...queries,
            offset,
            group: queries.group ? queries.group : 'category'
        },
        fetchPolicy: "network-only"
    }
    )

    if (error) {
        console.log({...error})
    }

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.payments?.length,
                group: queries.group ? queries.group : 'date'
            }
        })
    }
    return (
        <>
            <Main>
                <Container id="container">
                    <TableHeader />
                    <ItemListCont>
                        {
                            loading ?
                                <LoadingState />
                                :
                                error ?
                                    <ErrorState retryCallback={refetch} />
                                    :
                                    !data?.payments?.length ?
                                        <EmptyState
                                            clickCallback={refetch}
                                            message='No sales records so far'
                                            btnLabel='Refresh'
                                            subMessage='Invoices appears here when created'
                                        />
                                        :
                                        <>

                                            <TableCont>
                                                <ListGroupCont>
                                                    <InfiniteScroll
                                                        dataLength={data?.payments?.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        loader={<></>}
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                        <PageCont>{
                                                            data.payments.map((record: any) => (
                                                                <div key={record.id}>
                                                                    <p className='date--separator'>{format_date(record.id)}</p>{
                                                                        record.group.map((grp: any, i: number) => (
                                                                            <OuterList
                                                                                {...props}
                                                                                list={grp.subGroup}
                                                                                count={grp.count}
                                                                                total={grp.subGroupTotal}
                                                                                groupId={grp._id.paymentMethod}
                                                                                key={`${grp._id.day}${grp._id.month}${grp._id.year}`}
                                                                            />
                                                                        ))
                                                                    }
                                                                    <Footer id="group--footer">
                                                                        <Divider bottom={100} />
                                                                        <p>Total</p>
                                                                        <h6>{formatFigures(record.total)}</h6>
                                                                        <Divider />
                                                                    </Footer>
                                                                </div>
                                                            ))
                                                        }
                                                        </PageCont>
                                                    </InfiniteScroll>
                                                </ListGroupCont>
                                            </TableCont>
                                            <InfiniteScroll
                                                dataLength={data.payments.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            >
                                                <PageCardCont>
                                                    {
                                                        data.payments.map((record: any, i: number) => (
                                                            <div className='card--cont' key={record.id}>
                                                                <Separator i={i} className='date--separator'>{format_date(record.id)}</Separator>{
                                                                    record.group.map((grp: any, i: number) => (
                                                                        <PaymentCard
                                                                            {...props}
                                                                            list={grp.subGroup}
                                                                            count={grp.count}
                                                                            total={grp.subGroupTotal}
                                                                            groupId={grp._id.paymentMethod}
                                                                            key={`${grp._id.day}${grp._id.month}${grp._id.year}`}
                                                                        />
                                                                    ))
                                                                }
                                                                <Footer>
                                                                    <Divider bottom={100} />
                                                                    <p>Total</p>
                                                                    <h6>{formatFigures(record.total)}</h6>
                                                                    <Divider />
                                                                </Footer>
                                                            </div>
                                                        ))
                                                    }
                                                </PageCardCont>
                                            </InfiniteScroll>
                                        </>
                        }
                    </ItemListCont>
                </Container>
                <SideNavigator />
                <InvoiceModal />
                <LocalCheckOutModal />
                <CheckOutOnlineModal />
            </Main>
        </>
    )

}

export default Payments