import React, { } from 'react'
import { AddButtonCont, SubBtnCont } from './styles'
import { Plus } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { showCashBookModal, tableState } from '../../../../globals'

const AddStockBtn = (props: any) => {
    
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showCashBookModal(true)
    }
    return (
        <AddButtonCont
            show={true}
            onClick={() => showEditForm()}>
            <Icon>
                <Plus />
            </Icon>
        </AddButtonCont>
    )
}

export default AddStockBtn
export const AddSupplierBtn = (props: any) => {
    
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showCashBookModal(true)
    }
    return (
        <SubBtnCont
            show={true}
            onClick={() => showEditForm()}>
            <Icon>
                <Plus />
            </Icon>
        </SubBtnCont>
    )
}

