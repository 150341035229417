import styled from "styled-components";
export const Container = styled.div.attrs({
  className: "container",
})`
max-width: 1000px;
padding: 10px 0px;
`

export const ChatCont = styled.div<any>`
  width: 300px;
  height: 90%;
  right: 50px;
  z-index: 1000000;
  position: fixed;
  overflow: hidden;
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;
  display: grid;
  grid-template-rows: 50px 1fr;
  transition: all .2s ease-in-out;
  bottom: ${(props) => (props.opened ? 0 : -82.5)}%;
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
`;

export const FileInput = styled.input.attrs({
  type: 'file'
})`
  height:100%;
  width: 100%;
  right:0;
  top: 0;
  position: absolute;
  font-size: 10;
  opacity: 0;
  z-index: 20000;
`

export const StockFormItem = styled.form`
    width: 300px;
    height:100vh;
    z-index: 26000;
    overflow: hidden;
    overflow-x: hidden;
    gap: 0px 10px;
    border-radius: 10px;
    background: white;
    padding: 0px 0px;
    grid-template-rows: 40px auto 45px;
    right: 0px;
    top: ${props => (props.id) ? 'auto' : ''};
    position: absolute;
    .formGroup {
        margin-right: 10px;
    }
    @media(max-width: 768px) {
      width: 100%;
      background: inherit;
      /* padding-bottom: 100px; */
      /* background: yellow; */
      /* position: relative; */
      /* bottom: 80px; */
    }
`;
export const HeaderCont = styled.header`
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    justify-content: space-between;
     h6 {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .icon {
        height: 14px;
        width: 14px;
        cursor: pointer;
    }
    @media(max-width: 768px) {
      padding: 0px 14px;
      .icon {
         height: 16px;
        width: 16px;
        svg {
          stroke: #25ec78;
        }
      }
    }
`
export const ColumnCont = styled.div<any>`
  width: 100%;
   height: calc(100vh - 105px);
  overflow: scroll ;
  display: grid;
  grid-template-rows: auto auto auto;
  padding: 0px 10px;
  overflow-x: hidden;
  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    padding: 0px;
  }
`;
export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
  width: ${(props) => props.w || 100}%;
  border: 1px solid rgb(0 0 0 / 10%);
  @media(max-width: 768px) {
    /* padding: 0px 10px; */
  }
`;
export const BtnCont = styled.div<any>`
  position: relative;
  width:  100%;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: end;
  @media(max-width: 768px) {
    /* display: none; */
  }
`;

export const FormGroup: any = styled.div<any>`
  display: grid;
  position: relative;
  padding: 5px 6px;
  grid-template-columns: 1fr 15px;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  background: ${(props) => (props.focused ? 'rgb(0 0 0 / 10%)' : "initial")};
  border-bottom-left-radius: ${(props) => (props.top ? "0px" : "10px")};
  border-bottom-right-radius: ${(props) => (props.top ? "0px" : "10px")};
  border-bottom: ${(props) => (props.top ? "1px" : "0px")} solid rgb(0 0 0 / 10%);
  border-bottom: ${(props) => (props.off ? "0px" : "initial")} solid rgb(0 0 0 / 10%);
  border-right: ${(props) => (props.left ? "1px" : "0px")} solid rgb(0 0 0 / 10%);
  /* border-bottom-color: ${(props) => (props.top ? "#e6e1e1" : "white")}; */
  .icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
    position: relative;
    svg {
      stroke: grey;
    }
    :hover {
        background: whitesmoke;
    }
  }
  #cat {
    width: 13px;
    height: 13px;
    svg {
      stroke: grey;
    }
  }
  @media(max-width: 768px) {
    height: 50px;
  }
  
`;

export const Column = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
    position: relative;
    .fgroup:last-child {
        margin-bottom: 0px;
    }
    h6 {
      margin-bottom: 4px 0px;
      font-size: 12px;
      position: relative;
      font-weight: 700;
    }
    @media(max-width: 768px) {
      background: white;
      padding: 16px 16px;
      height: auto;
    }
`;
export const ImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 10px;
    width: 280px;
    height:270px;
    margin: auto;
    width: 100%;
    background: whitesmoke;
    overflow:hidden;
    break-inside: avoid;
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        mix-blend-mode: multiply;
        filter: contrast(1);
        vertical-align: top center;
        @media (max-width: 768px) {
        object-fit: cover;
        }
    }
    @media(max-width: 768px) {
      width: 100%;
      max-height: 300px;
      height: 300px;
      border-radius: 14px;
    }
`