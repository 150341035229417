import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { showStockEditModal, showCashBookModal, tableState } from '../../globals'
import StockForm from '../../pages/inventory/components/forms/stock'
import StockDetails from '../../pages/inventory/components/stockDetails'
import { FormContainer, StockModalCont } from './styles'
import ExpenseForm from '../../pages/expenses/components/forms/expense'


const ExpenseModal = () => {
    const show = useReactiveVar(showCashBookModal)
    const { state, item } = useReactiveVar(tableState)
    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }
    return (
        <StockModalCont onClick={(e: Event) => close(e)} show={show}>
            {/* <FormContainer>
                {
                    state === 'editing' ? <ExpenseForm expense={item} /> : state === 'info' ? <StockDetails stock={item} /> : null
                }
            </FormContainer> */}
        </StockModalCont>
    )
}

export default ExpenseModal