
import { makeVar } from '@apollo/client';
import { Invoice } from '../types/model';
import { USERPERMS, initInvoice } from './defaults';
import { initOrder } from '../models';
interface TState  {
    item: any|null;
    state: string | '';
}

const state:TState = {
    item: null,
    state: ''
}

const initUser = {
    info: {
        name: '',
        phone: '',
        email: '',
        dp: '',
    },
    username: '',
    password: '',
    
}

export const showStaffModal = makeVar(false);
export const showNav = makeVar(false);
export const setModal = makeVar(false);
export const showAccModal = makeVar('');
export const showCashBookModal = makeVar(false);
export const showInventoryModal = makeVar(false);
export const showStockEditModal = makeVar(false);
export const showMainCheckOut = makeVar(false);
export const showCheckOut = makeVar(false);
export const showChat = makeVar(false);
export const showListMenu = makeVar('');

export const globalUser = makeVar(initUser);
export const chatUser = makeVar(null);
export const openChatBox = makeVar(false);
export const chatView = makeVar('contacts');
export const chatID = makeVar('');
export const showOnlineCheckOutModal = makeVar(false);
export const showCheckOutModal = makeVar(false);
export const showInvoiceModal = makeVar(false);
export const showNavModal = makeVar(false);
export const tableState = makeVar({ ...state });
export const chartDuration = makeVar('wk');
export const perms:any = makeVar(USERPERMS);
    
export const cart = makeVar({...initInvoice});
export const onlineCart = makeVar({...initOrder});

