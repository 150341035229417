import React from 'react'
import { Footer, Header, ItemsCont } from './styles'

import { useReactiveVar } from '@apollo/client';

import { Link } from 'react-router-dom';
import { formatFigures, format_date } from '../../../../utils';
import { queryOptions } from '../../../../types/defaults';
import { Expense } from '../expense';
import { Divider } from '../../../../components/icons/styles';
import { Expense as ExpenseModel } from '../../../../types/model';

import { Payout } from '../payout';
import { History } from '../history';

const HistorySubList = (props: any) => {
    let { list: items, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)

    const generateLink = (group:string,id: string)=>{
        return `/expenses?p=${group}&q=${encodeURIComponent(id)}`;
    }

    return (
        <>
            <Header>
                <h6>{(group === 'date' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}</h6>
                {
                    count-10 > 0 && 
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                }
                <Divider />
            </Header>
            {
                // items.map((expense: any) => (
                    <ItemsCont >
                        <History {...props}  />
                    </ItemsCont>
                //     )
                // )
            }
            <Footer>
                <p>+{count - 10 <= 0 ? 0 : count - 10}</p>
                <h6>{formatFigures(total)}</h6>
            </Footer>
        </>
    )
}

export default HistorySubList