import { useMutation, useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"

import { DetContainer, HeaderCont, Item, ListItem, StockImageCont } from "./styles"
import { showCashBookModal, tableState } from "../../../../globals"
import { initSupplier } from "../forms/suppliers"
import { Divider, Icon } from "../../../../components/icons/styles"
import { formatMoney, format_date, simplifyExpDate, getExpStatus } from "../../../../utils"
import { CancelBtn, CloseBtn } from "../buttons/styles"
import { CancelIcon, TimesIcn } from "../../../../components/icons"
import { initProduct } from "../../../../globals/defaults"
import { getImageUrl } from "../../../../apollo"
import { initPayout } from "../../../../types/defaults"



const StockDetails = (props: any) => {

    const { state, item } = useReactiveVar(tableState)

    let [stock, setStock] = useState(initProduct);

    const status = item ? getExpStatus(stock) : '';

    let colorMark = ''

    if (status) {
        colorMark = status === 'weak' ? '#ffd108' : status === 'expired' ? 'red' : ''
    }

    useEffect(() => {
        setStock( item||initProduct)
    }, [item])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    return (
        <DetContainer show={state === 'details'}>
            <HeaderCont>
                <h6>Product details</h6>
                <CloseBtn title="Close" onClick={close}>
                    <Icon>
                        <TimesIcn size={8} color='black' />
                    </Icon>
                </CloseBtn>
            </HeaderCont>
            <ListItem>
                <Item>
                    <p>Name</p>
                    <p>{stock?.name}</p>
                </Item>
                <Item>
                    <p>Category</p>
                    <p>{stock?.categories.category || '-'}</p>
                </Item>
                <Item>
                    <p>Description</p>
                    <p>{stock?.description||'-'}</p>

                </Item>
                <Item> 
                    <p>Size</p>
                    <p>{stock?.size.val||'-'} {stock?.size.unit}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Price</p>
                    <p>{formatMoney(stock?.sellingPrice)}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>Stock</p>
                    <p>{stock?.q.val} {stock?.q.unit}</p>
                </Item>
                <Item>
                    <p>Expiry</p>
                    <p style={{ color: colorMark }}>{stock?.expiry ? simplifyExpDate(stock?.expiry) : '---'}</p>
                </Item>
                <Item>
                    <p>Date added</p>
                    <p>{format_date(stock?.createdAt||new Date())}</p>
                    <Divider />
                </Item>
                <Item>
                    <p>ID</p>
                    <p>{stock?._id?.slice(-6).toLocaleUpperCase()}</p>
                </Item>
                <StockImageCont> 
                    {
                        !stock?.mediaUrls[0] && <p>No image</p>
                    }
                    {stock?.mediaUrls[0] && <img src={getImageUrl(stock?.mediaUrls[0])} alt="" />}
                </StockImageCont>
            </ListItem>
        </DetContainer>
    )
}
export default StockDetails