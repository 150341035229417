import React from 'react'
import { Footer, Header, ItemsCont } from './styles'

import { useReactiveVar } from '@apollo/client';

import { Link } from 'react-router-dom';
import { formatFigures, formatMoney, format_date } from '../../../../utils';
import { queryOptions } from '../../../../types/defaults';
import { Expense } from '../expense';
import { Divider } from '../../../../components/icons/styles';
import { Expense as ExpenseModel } from '../../../../types/model';

import { Payout } from '../payout';

const PayoutSubList = (props: any) => {
    let { list: payouts, count, totalAmount,totalPaid, totalBal, groupId } = props;
    const { group } = useReactiveVar(queryOptions)

    const generateLink = (group:string,id: string)=>{
        return `/expenses?p=${group}&q=${encodeURIComponent(id)}`;
    }

    return (
        <>
            <Header>
                <h6>{(group === 'date added' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}</h6>
                {
                    count-10 > 0 && 
                    <h6>
                        <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                    </h6>
                }
                <Divider />
            </Header>
            {
                payouts.map((payout: any) => (
                    <ItemsCont key={payout._id}>
                        <Payout payout={payout} {...props}  />
                    </ItemsCont>
                    )
                )
            }
            <Footer id="group--footer">
                <p>+{count - 10 <= 0 ? 0 : count - 10}</p>
                {/* <h6>{formatMoney(totalAmount)}</h6>
                <h6>{formatMoney(totalPaid)}</h6> */}
                <h6>{formatMoney(totalBal)}</h6>
            </Footer>
        </>
    )
}

export default PayoutSubList