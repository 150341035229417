import React from 'react'
import { ImageCont } from './styles'
import { getImageUrl } from '../../../../apollo'
import { MultiIcn } from '../../../../components/icons'

function ImageItem(props: any) {
    const {multiple, source} = props
    return (
        <ImageCont>
        {source && <img src={getImageUrl(source)} alt="" />}
        {
                // multiple &&
                // <MultiIcn />
        }
        </ImageCont>
    )
}

export default ImageItem