import { format_date } from ".";
import { Expense, PayoutModel, Product, SupplierModel } from "../types/model";

export const updateProdCache = (prevProducts: any[], newProduct: Product, group: string) => {
    let i: number = -1

    switch (group) {
        case 'name':
            i = prevProducts.findIndex((prod: any) => prod._id === newProduct?.name.toUpperCase().charAt(0));
            break;

        case 'category':
            i = prevProducts.findIndex((prod: any) => prod.records[0].categories.category === newProduct?.categories.category);
            break;

        case 'instock':
            i = prevProducts.findIndex((prod: any) => +prod._id === newProduct?.q.val);
            break;

        case 'date':
            i = prevProducts.findIndex((prod: any) => prod._id === format_date(newProduct?.createdAt?.toString()));
            break;
        default:
            break;
    }

    if (i !== -1) {
        const j = prevProducts[i].records.findIndex((p: any) => p._id === newProduct._id);
        if (j !== -1) {
            prevProducts = prevProducts.map((prodObject: any, k: number) => {
                return i === k
                    ? {
                        ...prodObject,
                        records: prodObject.records.map((s: Product, n: number) => n === j ? newProduct : s),
                    }
                    : prodObject;
            });
        } else {
            prevProducts = prevProducts.map((prodObject: any, k: number) => {
                return i === k
                    ? {
                        ...prodObject,
                        count: prodObject.count + 1,
                        records: [newProduct, ...prodObject.records],
                        total: prodObject.total + newProduct.q.val * newProduct?.sellingPrice

                    }
                    : prodObject;
            });
        }
    } else {
        prevProducts = [
            {
                __typename: 'StocksGroup',
                records: [newProduct],
                count: 1,
                total: newProduct?.q.val * newProduct?.sellingPrice
            },
            ...prevProducts,
        ];
    }
    return prevProducts;
};
export const updateExpCache = (prevExpense: any[], newExpense: any, group: string) => {
    console.log(group)
    let i: number = -1
    switch (group) {
        case 'spender':
            i = prevExpense.findIndex((exp: any) => exp._id === newExpense?.spender.toUpperCase());
            break;
        case 'date':
            i = prevExpense.findIndex((exp: any) => format_date(exp._id.toString()) === format_date(newExpense?.createdAt?.toString()));
            break;
        default:
            i = prevExpense.findIndex((exp: any) => format_date(exp._id.toString()) === format_date(newExpense?.createdAt?.toString()));
            break;
    }
    if (i !== -1) {
        const j = prevExpense[i].records.findIndex((p: any) => p._id === newExpense._id);
        if (j !== -1) {
            prevExpense = prevExpense.map((expObject: any, k: number) => {
                return i === k
                    ? 
                    {
                        ...expObject,
                        records: expObject.records.map((e: Expense, n: number) => n === j ? newExpense : e)
                    }
                    : expObject;
            });
        } else {
            prevExpense = prevExpense.map((expObject: any, k: number) => {
                return i === k
                    ? {
                        ...expObject,
                        count: expObject.count + 1,
                        records: [newExpense, ...expObject.records],
                        total: expObject.total + newExpense.amount 
                    }
                    : 
                    expObject;
            });
        }
    } else {
        
        prevExpense = [
            {
                count: 1,
                records: [newExpense],
                total: newExpense.amount,
                __typename: 'ExpenseGroup',
                _id: (group === 'date' || group === '') ? newExpense.createdAt : newExpense.spender,
            },
            ...prevExpense,
        ];
    }
    return prevExpense;
};
export const updateSupCache = (prevSupplier: any[], newSupplier: any, group: string) => {
    console.log(group)
    let i: number = -1
    switch (group) {
        case 'name':
            i = prevSupplier.findIndex((sup: any) => sup._id === newSupplier?.name.toUpperCase());
            break;
        case 'date':
            i = prevSupplier.findIndex((sup: any) => format_date(sup._id.toString()) === format_date(newSupplier?.createdAt?.toString()));
            break;
        default:
            i = prevSupplier.findIndex((sup: any) => format_date(sup._id.toString()) === format_date(newSupplier?.createdAt?.toString()));
            break;
    }
    if (i !== -1) {
        const j = prevSupplier[i].records.findIndex((p: any) => p._id === newSupplier._id);
        if (j !== -1) {
            prevSupplier = prevSupplier.map((supObject: any, k: number) => {
                return i === k
                    ? 
                    {
                        ...supObject,
                        records: supObject.records.map((e: SupplierModel, n: number) => n === j ? newSupplier : e)
                    }
                    : supObject;
            });
        } else {
            prevSupplier = prevSupplier.map((e: any, k: number) => {
                return i === k
                    ? {
                        ...e,
                        count: e.count + 1,
                        records: [newSupplier, ...e.records],
                    
                    }
                    : 
                    e;
            });
        }
    } else {
        
        prevSupplier = [
            {
                count: 1,
                records: [newSupplier],

                __typename: 'SupplierGroup',
                _id: (group === 'date' || group === '') ? newSupplier.createdAt : newSupplier.name,
            },
            ...prevSupplier,
        ];
    }
    return prevSupplier;
};
export const updatePayOutCache = (prevPayOuts: any[], newPayOut: PayoutModel, newPayment:number, group: string) => {
    //console.log(`np: ${JSON.stringify(newPayOut, null)}`);


    let i: number = -1
    switch (group) {
        case 'name':
            i = prevPayOuts.findIndex((sup: any) => sup._id === newPayOut?.supplier.name.toUpperCase());
            break;
        case 'date':
            i = prevPayOuts.findIndex((sup: any) => format_date(sup._id.toString()) === format_date(newPayOut?.createdAt?.toString()));
            break;
        default:
            i = prevPayOuts.findIndex((sup: any) => format_date(sup._id.toString()) === format_date(newPayOut?.createdAt?.toString()));
            break;
    }
    if (i !== -1) {
        const j = prevPayOuts[i].records.findIndex((p: any) => p._id === newPayOut._id);
        if (j !== -1) {
            prevPayOuts = prevPayOuts.map((po: any, k: number) => {
                return i === k
                    ? 
                    {
                        ...po,
                        totalBal: po.totalBal - newPayment || 0,
                        records: po.records.map((p: PayoutModel, n: number) => n === j ? newPayOut : p)
                    }
                    : 
                    po;
            });
        } else {
            prevPayOuts = prevPayOuts.map((po: any, k: number) => {
                return i === k
                    ? {
                        ...po,
                        count: po.count + 1,
                        records: [newPayOut, ...po.records],
                        totalBal: po.totalBal + (newPayOut.amount-newPayOut.paid),
                    }
                    : 
                    po;
            });
        }
    } else {
        
        prevPayOuts = [
            {
                count: 1,
                records: [newPayOut],
                totalAmount: newPayOut.amount,
                totalPaid: newPayOut.paid,
                totalBal: newPayOut.amount - newPayOut.paid,
                __typename: 'PayoutGroup',
                _id: (group === 'date' || group === '') ? newPayOut.createdAt : newPayOut.supplier.name,
            },
            ...prevPayOuts,
        ];
    }
    return prevPayOuts;
};