import { gql } from '@apollo/client'

export const SAVE_SUPPLIER = gql`
    mutation saveSupplier($supplier: SupplierInput!) {
        saveSupplier(supplier: $supplier) { 
            _id
            name
            phone
            email
            accNumb
            bank
            company
            modifier
            createdAt
            updatedAt
        }
    }
`
export const DELETE_SUPPLIER = gql`
    mutation deleteSupplier( $id: String!) {
        deleteSupplier(id: $id) {
            _id
        }
    }
`
