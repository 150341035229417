import styled from "styled-components";
export const Container = styled.div.attrs({
  className: "container",
})`
max-width: 1000px;
padding: 10px 0px;
`
export const FormContainer = styled.div.attrs({ className: 'container' }) <any>`
  height: ${(props) => (props.show ? 80 : 0)}vh;
  width: 300px;
  left: 65%;
  position: fixed;
  padding-top: 0px;
  overflow-x: hidden;
  overflow: hidden;
  z-index: 2700000000000;
  transition: all 0.2s ease-in;
  bottom: ${(props) => (props.show ? 0 : -240)}%;
  @media(max-width: 768px) {
    transition: all 0.1s ease-in;
    width: 100%;
    border-radius: 14px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px;
    background: whitesmoke;
    height: ${(props) => (props.show ? 630 : 0)}px;
    left: 0;
    bottom: ${(props) => (props.show ? 0 : -240)}%;
  }
`; 

export const ChatCont = styled.div<any>`
  width: 300px;
  height: 90%;
  right: 50px;
  z-index: 1000000;
  position: fixed;
  overflow: hidden;
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;
  display: grid;
  grid-template-rows: 50px 1fr;
  transition: all .2s ease-in-out;
  bottom: ${(props) => (props.opened ? 0 : -82.5)}%;
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
`;

export const FileInput = styled.input.attrs({
  type: 'file'
})`
  height:100%;
  width: 100%;
  right:0;
  top: 0;
  position: absolute;
  font-size: 10;
  opacity: 0;
  z-index: 20000;
`

export const Form = styled.form`
    width: 300px;
    height:100vh;
    z-index: 57000000;
    overflow: hidden;
    overflow-x: hidden;
    gap: 0px 10px;
    border-radius: 14px;
    background: white;
    padding: 0px 0px;
    grid-template-rows: 40px auto 45px;
    right: 0px;
    top: ${props => (props.id) ? 'auto' : ''};
    position: absolute;
    .formGroup {
        margin-right: 10px;
    }
    @media(max-width: 768px) {
      width: 100%;
      background: inherit;
      /* padding-bottom: 100px; */
      /* background: yellow; */
      /* position: relative; */
      /* bottom: 80px; */
    }
`;
export const HeaderCont = styled.header`
    height: 50px;
    width: 100%;
    padding: 0px 10px;
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    justify-content: space-between;
     h6 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .icon {
        height: 14px;
        width: 14px;
        cursor: pointer;
    }
    @media(max-width: 768px) {
      padding: 0px 14px;
      h6{
      font-size: 16px;
      }
      .icon {
         height: 16px;
        width: 16px;
        svg {
          stroke: #25ec78;
        }
      }
    }
`
export const Sec1 = styled.div<any>`
  width: 100%;
  padding: 0px 10px;
  position: absolute;
  transition: all .15s ease-in-out;
  left: ${props => props.in ? 100 : 0}%;
  background-color: #ffffff;
  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    padding: 0px;
  }
`;
export const Sec2 = styled.div<any>`
//height: 100%;
width: 100%;
position: absolute;
padding: 0px 10px;
padding-bottom: 10px;
overflow: hidden;
transition: all 0.15s ease-in-out;
left: ${ (props) => (props.in ? 0 : -100) }%;
  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    padding: 0px;
  }
`;
export const BodyCont = styled.div<any>`
    height:70%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow: hidden;
    //background: yellow;
    
`;
export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
 
  width: ${(props) => props.w || 100}%;
  border: 1px solid rgb(0 0 0 / 10%);
  @media(max-width: 768px) {
    /* padding: 0px 10px; */
  }
`;
export const BtnCont = styled.div<any>`
  position: absolute;
  width:  100%;
  display: flex;
  padding: 0px 14px;
  align-items: center;
  bottom: 14%;
  @media(max-width: 768px) {
   bottom: 15%;
  }
`;

export const FormGroup: any = styled.div<any>`
  display: grid;
  position: relative;
  padding: 5px 6px;
  grid-template-columns: 1fr 15px;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  background: ${(props) => (props.focused ? 'rgb(0 0 0 / 10%)' : "initial")};
  border-bottom-left-radius: ${(props) => (props.top ? "0px" : "10px")};
  border-bottom-right-radius: ${(props) => (props.top ? "0px" : "10px")};
  border-bottom: ${(props) => (props.top ? "1px" : "0px")} solid rgb(0 0 0 / 10%);
  border-bottom: ${(props) => (props.off ? "0px" : "initial")} solid rgb(0 0 0 / 10%);
  border-right: ${(props) => (props.left ? "1px" : "0px")} solid rgb(0 0 0 / 10%);
  .icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
    position: relative;
    svg {
      stroke: grey;
    }
    :hover {
        background: whitesmoke;
    }
  }
  #cat {
    width: 13px;
    height: 13px;
    svg {
      stroke: grey;
    }
  }
  @media(max-width: 768px) {
    height: 55px;
  }
  
`;
export const ImageGroup: any = styled.div<any>`
  display: flex;
  position: relative;
  padding: 2px 2px;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  //border: 1px solid rgb(0 0 0 / 10%);
  p {
    font-size: 12px;
    margin-bottom: 0px;
    text-decoration: underline;
    padding-right: 4px;
    font-weight: 500;
  }
  @media(max-width: 768px) {
    height: 55px;
  }
  
`;

export const Column = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
    position: relative;
    .fgroup:last-child {
        margin-bottom: 0px;
    }
    h6 {
      margin-bottom: 4px 0px;
      font-size: 10px;
      position: relative;
      font-weight: 600;
    }
    @media(max-width: 768px) {
      background: white;
      padding: 4px 16px;
      height: auto;
      h6 {
        margin-bottom: 4px 0px;
        font-size: 12px;
        position: relative;
        font-weight: 500;
    }
    }
`;
export const ImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 6px;
    width: 43px;
    height:43px;
    background: whitesmoke;
    overflow:hidden;
    break-inside: avoid;
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        mix-blend-mode: multiply;
        filter: contrast(1);
        vertical-align: top center;
        @media (max-width: 768px) {
          object-fit: cover;
        }
    }
    @media(max-width: 768px) {
      width:  50px;
      height: 100%;
      border-radius: 8px;
    }
`
export const BackLink = styled.p<any>`
  position: absolute;
  width:  100%;
  padding: 0px 10px;
  margin-bottom: 0px;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  bottom: 8%;
  cursor: pointer;
  @media(max-width: 768px) {
     bottom:8%;
    }
`;
