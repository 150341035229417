import { Fragment, ReactElement } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_EXPENSES } from "../../graphql/queries/expenses";
import styled from "styled-components";
import { Container, ListGroupCont, Main, TableCont } from "./styles";
import { useLocation } from "react-router-dom";
import { queryOptions } from "../../types/defaults";
import LoadingState from "./components/pagestate/loader";
import ErrorState from "./components/pagestate/error";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyState from "./components/pagestate/empty";
import ExpenseListGroup from "./components/expenseGroup";
import MainHeader from "./components/headers/main";
import ExpenseModal from "../../components/modals/expenses";
import ExpenseCard from "../../components/cards/expense";
import SideNavigator from "../../components/sidenav";
import AddStockBtn, { AddSupplierBtn } from "./components/buttons/add";
import ExpenseForm from "./components/forms/expense";
import SupplierForm from "./components/forms/suppliers";
import MainAppHeader from "../../components/topnav/headers/main";

export const ItemWraper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
const Page = (props: any): ReactElement => {
    const location = useLocation();

    const queries = useReactiveVar(queryOptions) as any

    const { search } = useLocation()
    // const qs = queryString.parse(search)

    let offset = 0

    const { loading, data, error, refetch, fetchMore, subscribeToMore } = useQuery(GET_EXPENSES, {
        variables: {
            ...queries,
            offset,
            group: queries.group ? queries.group : 'date',
        },
        fetchPolicy: "network-only",
    })

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.expenses?.length,
            }
        })
    }

    // let expenses:any = [];
    if (data) {
        // expenses = data?.expenses
        // console.log(data);
    }

    return (
        <>
            <MainAppHeader pageTitle='Expenses' />
            <Main>
               
                <Container id="container">
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data.expenses.length ?
                                    <EmptyState
                                        // clickCallback={openEditors}
                                        btnLabel='Add'
                                        message='No expense so far'
                                        subMessage='Expense appears here when created'
                                    />
                                    :
                                    <>
                                        <TableCont>
                                            <ListGroupCont>
                                                <InfiniteScroll
                                                    dataLength={data.expenses.length}
                                                    next={fetchMoreData}
                                                    hasMore={true}
                                                    loader={null}
                                                    style={{ overflow: 'visible' }}
                                                >
                                                    {
                                                        data.expenses.map((items: any) => (
                                                            <ExpenseListGroup
                                                                {...props}
                                                                key={items._id}
                                                                groupId={items._id}
                                                                list={items.records}
                                                                count={items.count}
                                                                total={items.total}
                                                            />
                                                        ))
                                                    }
                                                </InfiniteScroll>
                                            </ListGroupCont>
                                        </TableCont>
                                        <InfiniteScroll
                                            dataLength={data.expenses.length}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            loader={null}
                                            style={{ overflow: 'visible' }}
                                        > {
                                                data.expenses.map((items: any) => (
                                                    <ExpenseCard
                                                        {...props}
                                                        key={items._id}
                                                        groupId={items._id}
                                                        list={items.records}
                                                        count={items.count}
                                                        total={items.total}
                                                    />
                                                ))
                                            }
                                        </InfiniteScroll>
                                    </>
                    }
                            </Container>
                <ExpenseModal />
                <ExpenseForm />
                <SideNavigator />
                <AddSupplierBtn />
            </Main>
        </>
    )
}

export const ExpensePage = Page;
