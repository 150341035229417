import { Divider } from '../../../../../components/icons/styles'
import { queryOptions } from '../../../../../types/defaults'
import { ListCont, ListItem } from './styles'

const Actions = (props: any) => {
    const { options, name, closeCallback } = props
    const groups = ['name', 'category', 'instock', 'price', 'date']
    const filters = ['','expiring', 'expired', 'low_stocks', 'out_of_stock']

    const selectOption = (i: number) => {
        name === 'group' ? 
        queryOptions({
             ...queryOptions(),
             group: groups[i] 
        }) 
        :
        queryOptions({
             ...queryOptions(), 
             filter: filters[i] 
        })
        closeCallback()
    }
    return (
        <ListCont onMouseLeave={closeCallback}>
            {
                options.map((opt: string, i: number) => (
                    <ListItem active={
                        (name === 'group' && queryOptions().group === groups[i]) || (name === 'filter' && queryOptions().filter === filters[i])
                        } 
                        onClick={() => selectOption(i)}>
                        <p>{opt}</p> 
                    </ListItem>
                ))
            }
        </ListCont>
    )
}
export default Actions