
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import {
    Card,
    Footer, Header, ItemsCont,
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import Invoice from './invoiceListItem'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'

type Props = {}

function AggregateCard(props: any) {

    const { list: { records }, count, total, groupId } = props;
    // console.log(`itesm: ${JSON.stringify((items), null, 2)}`)
    const { group } = useReactiveVar(queryOptions)

    const generateLink = () => {
        return `/sales/aggregates/view?p=${group}&q=${encodeURIComponent(groupId)}`;
    }

     console.log(`iid: ${groupId}`)



    return (
        <ItemsCont>{
            <>
            <Header>
                    {format_date(groupId)}
                    {
                        count > 5 &&
                        <h6>
                            <Link to={generateLink()}>SHOW ALL</Link>
                        </h6>
                    }
                </Header>
            <Card>
                {
                    records.map((record: any, i: number) => (
                        <Invoice
                            index={i}
                            key={record._id}
                            invoice={record}
                            count={count}
                            // stock={record.stocks[0]}
                        />
                    ))
                }
                <Footer>
                    <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                    <Icon rot={90} size={6}>
                        <Chevron />
                    </Icon>
                    <h6>{roundAmount(total)}</h6>
                </Footer>
            </Card>
            </>
        }
        </ItemsCont>
    )
}

export default AggregateCard