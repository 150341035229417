import { useQuery } from "@apollo/client"
import { GET_ALL_SUPPLIERS } from "../../../../../graphql/queries/supplier.query"
import { ListCont, Item } from "./styles"
import LoadingState from "../../pagestate/loader"
import ErrorState from "../../pagestate/error"

export const SuppliersDropDownList = (props: any) => {
    const { options, header, name, closeCallback, selectCallback } = props
    const { loading, data, error } = useQuery(GET_ALL_SUPPLIERS, {
        variables: {
            // ...queries,
            // offset,
            // group: queries.group ? queries.group : 'date',
        },
        fetchPolicy: "network-only",
    })

    if (error) console.log({ ...error })
    return (
        <ListCont {...props}>
            {
                loading?
                <LoadingState />
                :
                error ?
                <p className="error">Error searching for your suppliers</p>
                :
                !data.allSuppliers.length ?
                <p className="empty">No suppliers found</p>
                :
                data.allSuppliers.map((sup: any, i: number) => (
                    <Item key={sup._id} onClick={() => selectCallback(sup)}>
                        <p>{sup.name}</p>
                    </Item>
                ))
            }
        </ListCont>
    )
}
export const DropDownList = (props: any) => {
    const { options, header, name, closeCallback, selectCallback } = props
    return (
        <ListCont {...props}>
            {
                options.map((opt: any, i: number) => (
                    <Item key={i} onClick={() => selectCallback(opt)}>
                        <p>{opt}</p>
                    </Item>
                ))
            }
        </ListCont>
    )
}