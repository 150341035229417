import { ReactElement, useState } from "react";
import { useMutation } from "@apollo/client";

import { PaymentPropsList, PaymentCont, PropItem} from "./styles";
import { Divider, Icon } from "../../../../components/icons/styles";
import { More } from "../../../../components/icons";
import { DELETE_EXPENSE } from "../../../../graphql/mutations/expense";

import { formatMoney } from "../../../../utils";
import { MoreActions } from "../listMenu";
import { queryOptions } from "../../../../types/defaults";
import { showCashBookModal, tableState } from "../../../../globals";
import { GET_PAYOUTS } from "../../../../graphql/queries/payout.query";
import { ImageItem } from "../forms/share/imageView";
import { DELETE_PAYOUT } from "../../../../graphql/mutations";

export function Payout(props: any): ReactElement {

    const [menuOpened, reSetMenu] = useState(false);

    const { payout, editCallback } = props;

    const togleMenu = () => {
        reSetMenu(!menuOpened)
    }
    const closeMenu = () => {
        reSetMenu(false)
    }

    const [deletePayOut, { error, loading }] = useMutation(DELETE_PAYOUT, {

        update: (cache, { data: { deletePayout: deleted } }) => {
            const cached: any = cache.readQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queryOptions()
                }
            })

            console.log(JSON.stringify(cached, null, 2))

            let newPayOut = cached.payouts.map((po: any) => {
                return (po.records.some((p: any) => p._id === deleted._id)) ?
                    ({
                        ...po,
                        totalBal: po.totalBal - deleted.paid,
                        records: po.records.filter((p: any) => p._id !== deleted._id)
                    })
                    :
                    po
            })

            cache.writeQuery({
                query: GET_PAYOUTS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    payouts: newPayOut.filter((p: any) => p.records.length)
                }
            });
        }
    });

    if (error)
        console.log({ error });

    const deleteItem = (e: any, id: string ) => {
        e.stopPropagation();
        closeMenu()
        deletePayOut({
            variables: {
                id
            },
        })
    }

    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: payout,
        })
        showCashBookModal(true)
    }
    
    return (
        <PaymentCont>
            <ImageItem name={payout.supplier.name} />
            <PaymentPropsList>
                <PropItem 
                >
                    <p>{payout.supplier.name}</p>
                    <p>{payout.supplier.phone}</p>
                </PropItem>
                <PropItem>
                    <p>{payout.remark}</p>
                </PropItem>
                <PropItem>
                    <p>N{formatMoney(payout.amount)}</p>
                </PropItem>
                <PropItem>
                    <p>N{formatMoney(payout.paid)}</p>
                </PropItem>
                <PropItem>
                    <Icon onClick={togleMenu}>
                        <More />
                    </Icon>
                    <p>N{formatMoney(payout.amount - payout.paid)}</p>
                </PropItem>
                <Divider />
            </PaymentPropsList>
            {
                menuOpened &&
                <MoreActions
                    closeMenuCallback={closeMenu}
                    actions={
                        [
                            {
                                label: 'Make payment',
                                callback: (e: any) => opendEditor(e, 'repayment'),
                            },
                            {
                                label: 'Edit',
                                callback: (e: any) => opendEditor(e, 'update'),
                            },
                            {
                                label: 'See details',
                                callback: (e: any) => opendEditor(e, 'details'),
                            },
                            {
                                label: 'Delete invoice',
                                callback: (e: any) => deleteItem(e, payout._id)
                            }
                        ]
                    }
                />
            }
        </PaymentCont>
    )
}
