import { useMutation, useReactiveVar } from "@apollo/client"
import { SyntheticEvent, useEffect, useState } from "react"
import { Cancel, Drop, TimesIcn } from "../../../../../components/icons"
import { Icon } from "../../../../../components/icons/styles"
import { showCashBookModal, tableState } from "../../../../../globals"

import { queryOptions } from "../../../../../types/defaults"
import { SupplierModel } from "../../../../../types/model"
import { stripTypename } from "../../../../../utils"
import { updateSupCache } from "../../../../../utils/caches"
import Button, { SaveButton } from "../../buttons"
import { Input } from "../inputs"
import { Double } from "../styles"
import { BtnCont, Column, ColumnCont, Form, FormContainer, FormGroup, FormGroupCont, HeaderCont } from "./styles"

import { SAVE_SUPPLIER } from "../../../../../graphql/mutations/supplier.mutation"
import { GET_SUPPLIERS } from "../../../../../graphql/queries/supplier.query"
import { CloseBtn } from "../../buttons/styles"

export const initSupplier: SupplierModel = {
    name: '',
    phone: '',
    email: '',
    accNumb: '',
    bank: '',
    company: ''
}
const SupplierForm = (props: any) => {
    
    const { state, item } = useReactiveVar(tableState)

    const queries = useReactiveVar(queryOptions)
    
    let [supplier, setSupplier] = useState(initSupplier);
    
    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    useEffect(() => {
        setSupplier(item || initSupplier)
    }, [item])

    const close = (e: Event) => {
        e.stopPropagation()
        tableState({
            item: null,
            state: ''
        })
        showCashBookModal(false)
    }

    const [saveSupplier, { error, loading, data }] = useMutation(SAVE_SUPPLIER, {
        update: (cache, { data: { saveSupplier: newSupplier } }) => {
            const prevSups: any = cache.readQuery({
                query: GET_SUPPLIERS,
                variables: {
                    ...queries,
                    group: 'date'
                }
            });
            //console.log(`prev: ${prevSups}`)
            cache.writeQuery({
                query: GET_SUPPLIERS,
                data: {
                    suppliers: updateSupCache(prevSups?.suppliers, newSupplier, queries.group),
                },
            });
        }
    });

    if (error) {
        console.log({ ...error })
    }

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = (name: string) => {
        setCancel(name);
    }
    const mouseLeave = (name: string) => {
        setCancel(name);
    }

    const handleClear = (name: string) => {
        setSupplier({
            ...supplier,
            [name]: ''
        })
    }

    const closeForm = (e:SyntheticEvent) => {
        e.preventDefault()
        tableState({
            state: '',
            item: null
        })
        showCashBookModal(false)
    }

    const handleChange = (e: any) => {
        e.persist();
        const { target: { name, value } } = e
        setSupplier({
            ...supplier,
            [name]: value
        })
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (supplier._id) {
            const { modified, ...sup } = stripTypename(supplier)
            const { name, phone, email, company } = sup;
            supplier = {
                ...sup,
                name: name.trim(),
                
                phone: phone.trim(),
                company: company.trim()
            }
        } else {
            const { name, phone, accNumb, email, company } = supplier;
            supplier = {
                ...supplier,
                name: name.trim(),
            }
        }
        saveSupplier({
            variables: {
                supplier
            }
        });
    }


    return (
    <FormContainer show={state==='editing'}>
        <Form
            noValidate={true}
            {...props}
            id={supplier?._id}
            onClick={(e: Event) => e.stopPropagation()}
            onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
        >
            <HeaderCont>
                    <h6> {state === 'editing'}</h6>
                    <CloseBtn title="Close" onClick={(e: any)=>closeForm(e)}>
                    <Icon>
                        <TimesIcn size={8} color='black' />
                    </Icon>
                </CloseBtn>
                {/* <SaveButton 
                    data={data}
                    loading={loading}
                    title={supplier?._id ? 'Update' : 'Add'}
                    disabled={
                        !supplier.name
                    }
                /> */}
            </HeaderCont>
            <ColumnCont>
                <Column>
                    <h6>Supplier</h6>
                    <FormGroupCont className="fgroup">
                        <FormGroup onMouseLeave={() => mouseLeave('name')} onMouseEnter={() => mouseEnter('name')} top>
                            <Input
                                required
                                name="name"
                                value={supplier.name}
                                label='Name'
                                placeholder='Full name'
                                focused={focused === 'name'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'name') &&
                                <Icon onClick={() => handleClear('name')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup
                            onMouseLeave={() => mouseLeave('company')}
                                onMouseEnter={() => mouseEnter('company')}>
                            <Input
                                name='company'
                                label='Company'
                                value={supplier.company}
                                focused={focused === 'company'}
                                placeholder='Company name'
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'company') &&
                                    <Icon onClick={() => handleClear('company')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>
                </Column>

                <Column>
                    <h6>Contact</h6>
                    <FormGroupCont>
                        <Double>
                                <FormGroup w={100} onMouseLeave={() => mouseLeave('phone')} onMouseEnter={() => mouseEnter('phone')}>
                                <Input
                                    required
                                    name='phone'
                                    label='Phone'
                                    placeholder='08012345678'
                                    value={supplier.phone || ''}
                                    focused={focused === 'phone'}
                                    changeCallback={handleChange}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'phone') &&
                                    <Icon >
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                        </Double>
                    </FormGroupCont>
                    <FormGroupCont className="fgroup">
                        <Double>
                                <FormGroup off w={100} onMouseLeave={() => mouseLeave('email')} onMouseEnter={() => mouseEnter('email')}>
                                <Input
                                    
                                    name='email'
                                    label='Email'
                                    placeholder='john@example.com'
                                    value={supplier?.email || null}
                                    focused={focused === 'email'}
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                        (cancel === 'email') &&
                                    <Icon >
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                        </Double>
                    </FormGroupCont>
                </Column>
                    <Column>
                        <h6>Account</h6>
                        <FormGroupCont className="fgroup">
                            <FormGroup onMouseLeave={() => mouseLeave('accNumb')} onMouseEnter={() => mouseEnter('accNumb')} top>
                                <Input
                                    name="accNumb"
                                    type="number"
                                    value={supplier.accNumb}
                                    label='Acc. Number'
                                    placeholder='Account number'
                                    focused={focused === 'accNumb'}
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'accNumb') &&
                                    <Icon onClick={() => handleClear('accNumb')}>
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                            <FormGroup
                                onMouseLeave={() => mouseLeave('bank')}
                                onMouseEnter={() => mouseEnter('bank')}>
                                <Input
                                    name='bank'
                                    label='Bank'
                                    value={supplier.bank}
                                    focused={focused === 'bank'}
                                    placeholder='Bank name'
                                    changeCallback={(e: any) => handleChange(e)}
                                    focusedCallback={(name: string) => focus(name)}
                                />
                                {
                                    (cancel === 'bank') &&
                                    <Icon onClick={() => handleClear('bank')}>
                                        <Cancel />
                                    </Icon>
                                }
                            </FormGroup>
                        </FormGroupCont>
                    </Column>
            </ColumnCont>
            <BtnCont>
                <Button 
                    data={data}
                    loading={loading} 
                    title={supplier?._id ? 'Update supplier':'Add supplier'} 
                    disabled={!supplier.name || !supplier.phone }
                />
            </BtnCont>
        </Form>
        </FormContainer>
    )
}
export default SupplierForm