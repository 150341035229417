import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../apollo'
import { Desc, DescCol, DescCont, MenuOptionsList, Mail, Phone, Title, Option, TitleCont, SupplierCont, ContactCont } from './styles'
import { ImageItem } from './image'
import { Divider, Icon } from '../../icons/styles'
import { Ellipsis } from '../../icons'
import { showCashBookModal, showInventoryModal, showListMenu, tableState } from '../../../globals'
import { GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { useMutation, useReactiveVar } from '@apollo/client'
import { DELETE_PRODUCT } from '../../../graphql/mutations'
import { GET_SUPPLIERS } from '../../../graphql/queries/supplier.query'
import { DELETE_SUPPLIER } from '../../../graphql/mutations/supplier.mutation'

const SuplierItem = (props: any) => {
    const {supplier, count, index}= props
    const [showMenu, setShowMenu] = useState<any>(false)

    const shownMenu =  useReactiveVar(showListMenu)

    const selectListOption = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }
    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: supplier,
        })
        setShowMenu(false)
        showCashBookModal(true)
    }

    const [deleteSupplier, { error, loading }] = useMutation(DELETE_SUPPLIER, {
        update: (cache, { data: { deleteSupplier: deleted } }) => {
            const cached: any = cache.readQuery({
                query: GET_SUPPLIERS,
                variables: {
                    ...queryOptions()
                }
            })

            let newSuppliers = cached.suppliers.map((supps: any) => {
                return (supps.records.some((r: any) => r._id === deleted._id)) ?
                    ({
                        ...supps,
                        records: supps.records.filter((s: any) => s._id !== deleted._id)
                    })
                    :
                    supps
            })
            cache.writeQuery({
                query: GET_SUPPLIERS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    suppliers: newSuppliers.filter((s: any) => s.records.length)
                }
            });
        }
    });

    if (error) console.log({ error })

    const onDeleteSupplier = (e: any, id: string ) => {
        
        e.stopPropagation();

        console.log(id)
        deleteSupplier({
            variables: {
                id
            },
        })
    }
  return (
      <SupplierCont>
          <ImageItem name={supplier.name} />
          <DescCol>
              <DescCont>
                  <TitleCont>
                      <Title>{supplier.name}</Title>
                      <Icon onClick={(e: SyntheticEvent) => showListMenu(supplier._id)} rot={90} >
                          <Ellipsis />
                      </Icon>
                      <MenuOptionsList show={shownMenu === supplier._id}>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e,'editing')}>
                              <p>Edit</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e, 'details')} >
                              <p>See details</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => onDeleteSupplier(e, supplier._id) }>
                              <p style={{ color: 'red' }}>Delete</p>
                          </Option>
                      </MenuOptionsList>
                  </TitleCont>
                  <ContactCont>
                      <Phone>{supplier.phone}</Phone>
                      <Mail>{supplier.company}</Mail>
                  </ContactCont>
              </DescCont>
              {/* <ContactCont>
                  <Phone>08022778054</Phone>
                  <Mail>aminu@example.com</Mail>
              </ContactCont> */} 
              {
                  index + 1 !== count && <Divider bottom={-12} />
              }
          </DescCol>
      </SupplierCont>
  )
}

export default SuplierItem