
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import {
    Card, Desc,
    DescCol, DescCont,
    Footer, Header,
} from './styles'
import { roundAmount } from '../../../utils'

import StockListItem from './stockListItem'
import Skeleton from '../../loaders/skeletons'
import { useReactiveVar } from '@apollo/client'
import { queryOptions } from '../../../types/defaults'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {}

function AllStocksCard(props: any) {

    const { list: items, groupId, fetchMoreCallback } = props;

    // const { filter } = useReactiveVar(queryOptions)
    return (
        <>
          <Header>
                <p>{groupId}</p>
            </Header>
            <Card>

                {
                    items.map((item: any, index: number) => (
                        <StockListItem index={index} stock={item} count={items.length} key={item._id} />
                    ))
                }
                <Footer>
                </Footer>
            </Card> 
        </>
        
    )
}

export default AllStocksCard