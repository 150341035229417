import { queryOptions } from "../../../types/defaults";
import { CheckIcon } from "../../icons";
import { Icon } from "../../icons/styles";
import { Opt } from "./styles";

export const MenuOpt = (props: any) => {
    const { selectOptCallback, options } = props;
  
    return (
        <> {
            options.map((opt: string) =>
            (
                <Opt key={opt} onClick={() => selectOptCallback(opt === 'Supplier' ? 'name' : opt.toLowerCase())}>
                    <p className="lbl">{opt}</p>
                    {
                        queryOptions().group === opt.toLowerCase() &&
                        <Icon size={12}>
                            <CheckIcon color="grey" />
                        </Icon>
                    }
                </Opt>
            )
            )
        }
        </>
    )
}