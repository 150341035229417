import { CartItem } from "../models";
import { Invoice } from "../types/model";


export const genId = () => Math.floor(Math.random() * (1000000000000 - 100000000000 + 1) + 100000000000).toString();

export const inDevelopment = process.env.NODE_ENV === 'development'
export const getLocalStore =  () => {
    const account: any = localStorage.getItem('account') as any
    return account ? JSON.parse(account) : {}
}

export const simplifyExpDate = (expiry: string) => {
    const yr = new Date(+expiry).getFullYear().toString().slice(-2)
    const mn = new Date(+expiry).getMonth() + 1
    const simpleDate = `${mn < 10 ? '0' : ''}${mn}/${yr}`
    return simpleDate
}



export const getNetInvoiceToatal = (invoiceGroup: any[]) => {
    return invoiceGroup.reduce((total: number, invoice: Invoice) => total + invoice.recieved, 0)
}

export const sortByPaid = (list: any[]) => {
    let n: any = []
    let m: any = []
    list.forEach((q: any) => {
        q.amountPaid < q.item.sellingPrice * q.quantity ? m.push(q) : n.push(q)
    })

    return [...n, ...m];
}

export const getCartTotal = (items: CartItem[]) => {
    return items.length > 0 ? items.reduce((total: number, i: CartItem) =>
        total + i?.item?.sellingPrice * i.quantity, 0
     ) : 0
}

export const getExpStatus = ({ expiry, expiryWarning }: any) => {
    let status = null;

    if (expiry && expiryWarning) {
        const m1 = new Date().getMonth() + 1
        const m0 = new Date(+expiry).getMonth() + 1
        const y1 = +new Date().getFullYear().toString().slice(-2)
        const y0 = +new Date(+expiry).getFullYear().toString().slice(-2)

        if (y0 - y1 > 0) {
            status = 'active'
        } else if (y0 - y1 < 0) {
            status = 'expired'
        } else if (y0 - y1 === 0) {
            if (m0 - m1 <= 0) {
                status = 'expired'
            } else if (m0 - m1 > 0) {
                if (m0 - m1 <= +expiryWarning) {
                    status = 'weak'
                } else {
                    status = 'active'
                }
            }
        }
    } else if (expiry) {
        const m1 = new Date().getMonth() + 1
        const m0 = new Date(+expiry).getMonth() + 1
        const y1 = +new Date().getFullYear().toString().slice(-2)
        const y0 = +new Date(+expiry).getFullYear().toString().slice(-2)

        if (y0 - y1 > 0) {
            status = 'active'
        } else if (y0 - y1 < 0) {
            status = 'expired'
        } else if (y0 - y1 === 0) {
            if (m0 - m1 <= 0) {
                status = 'expired'
            } else {
                status = 'active'
            }
        }
    }
    return status
}
export const formatFigures = (amount: number) => {
    if (amount.toString().length >= 7) {
        return `${(amount / 1000000).toFixed(1)}M`;
    }
    else if (amount.toString().length === 6) {
        return `${(amount / 1000).toFixed(1)}K`;
    }
    else if (amount.toString().length === 5) {
        return `${(amount / 1000).toFixed(1)}K`;
    } else if (amount.toString().length === 4) {
        return `${(amount / 1000).toFixed(1)}K`;
    }
    else {
        return amount.toFixed(0);
    }
};
export const format_date = (date: any) => {
    return new Date(+date)
    .toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
}

export const formatTime = (date: any) => {
    const d = new Date(+date)
    const time = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }).format(d)
    return time
}

export const formatMoney = (money: any) => new Intl.NumberFormat('en-US').format(+money)



export const stripTypename = (object: any) => {
    const { __typename, ...sanitizedObject } = object
    return sanitizedObject
}
export const genTransId = () => {
    const id =  Math.floor(1000000000 + Math.random() * 9000000000).toString();
    console.log(`id: ${id}`)
    return id
    // let m = new Date().toLocaleDateString().split('/')

    // m[0] = m[0].length === 1 ? "0" + m[0] : m[0]
    // m[1] = m[1].length === 1 ? "0" + m[1] : m[1]

    // const d = m[1] + m[0] + m[2]
    // const t = new Date().toLocaleTimeString().split(' ')[0].replaceAll(':', '')
    // return d + t;
}

export const roundAmount = (amount: number) => {
    if (amount.toString().length >= 7) {
        return `${(amount / 1000000).toFixed(1)}M`;
    }
    else if (amount.toString().length === 6) {
        return `${(amount / 1000).toFixed(1)}K`;
    }
    else if (amount.toString().length === 5) {
        return `${(amount / 1000).toFixed(1)}K`;
    } else if (amount.toString().length === 4) {
        return `${(amount / 1000).toFixed(1)}K`;
    }
    else {
        return amount.toFixed(0);
    }
};
export const getBg = () => {
    const bgs = [
        '#f0f8ff', '#f5f5dc', '#fdfd96', '#caffbf', '#ffcccb', '#c1e1c1', '#ffebcd', '#e6e6fa',
        '#f5fffa', '#faebd7', '#d3f8e2', '#e4f9f5', '#f4f1de', '#f5f0e1', '#e0fbfc', '#fdffb6',
        '#a8dadc', '#c2f2d0', '#bde0fe', '#f3d2c1', '#f7f7f9', '#eef4f3', '#ffdeeb', '#ffe5d9',
        '#f5e5fc', '#e4c1f9', '#ffc3a0', '#fff5ba', '#f4d35e', '#edf6f9', '#e9ecef', '#fff4e6',
        '#f6bd60', '#e1f7d5', '#bde4a7', '#d9f7be', '#fffaf0', '#f0efeb', '#f8edeb', '#ffe0ac'
    ];

    return bgs[Math.floor(Math.random() * bgs.length)];
}
 