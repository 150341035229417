import React from 'react'
import { HamdbugerMenu, HeadItemsCont, HeaderCont, HeaderMenuCont, ImageCont, MenuCont } from './styles';
import { NavLink } from 'react-router-dom';
import { getBg } from '../../../../utils';
import { showNavModal } from '../../../../globals';
import { Icon } from '../../../icons/styles';
import { LogoIcn } from '../../../icons';

const TopBar = (props: any) => {
    const { pageTitle} = props;
    const toggleSideNav = () => {
        showNavModal(true)
    }
  return (
      <HeaderCont id="container">
          <HeadItemsCont>
              <div>
                  <NavLink className='home' to='/'> </NavLink>
                  <MenuCont>
                      <HamdbugerMenu id='mob' onClick={() => toggleSideNav()}>
                          <div className="bar"></div>
                          <div className="bar"></div>
                          <div className="bar"></div>
                      </HamdbugerMenu>
                      <Icon id="logo">
                        <LogoIcn />
                      </Icon>
                      <h6>{pageTitle}</h6>
                  </MenuCont>
              </div>

              <h6 className='page__title'>Stosyst</h6>

              <HeaderMenuCont>
                  <ImageCont bg={getBg()}>
                      <img src="https://i.pinimg.com/736x/47/3e/84/473e84e35274f087695236414ff8df3b.jpg" alt="user" />
                  </ImageCont>
              </HeaderMenuCont>
          </HeadItemsCont>
      </HeaderCont>
  )
}

export default TopBar