
import { getImageUrl } from "../../../apollo"
import { simplifyExpDate } from "../../../utils"
import { MultiIcn } from "../../icons"
// import { DateIndictor, StockIndicator } from "../../images/styles"
import { DateIndictor, ImageCont, StockIndicator } from "./styles"

const ImageItem = (props: any) => {
    const { source, multiple, expiry, expiryStatus } = props
    // const exp = expiry ? simplifyExpDate(expiry) : null

    return (
        <ImageCont>
            {
                multiple &&
                <MultiIcn />
            }
            {/* {
                (expiryStatus && expiryStatus === 'weak' || expiryStatus === 'expired') ? <StockIndicator {...props} /> : <></>
            } */}
            {/* {source && <img src={getImageUrl(source)} alt="" />} */}
            <img src={source} alt="" />
            {/* {
                exp && <DateIndictor ><p>{exp}</p></DateIndictor>
            } */}
        </ImageCont>
    )
}
export { ImageItem }