
import { getBg } from "../../../utils"
import { ImageCont } from "./styles"

const ImageItem = (props: any) => {
    const { name } = props
    return (
        <ImageCont bg={getBg()}>
            <div>{name.charAt(0)}</div>
        </ImageCont>
    )
}
export { ImageItem }