import styled from "styled-components";

export const TableActionsCont = styled.div`
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0px;
        font-size: 12px;
    }
    .action {
        &:last-child {
            margin-left: 20px;
        }
    }
    .icon {
      width: 18px;
      height: 18px;
      stroke: grey;
  }
`
export const FilterCont = styled.div`
  display:flex;
  align-items: center;
  justify-content: end;
  .filter__cont {
    display: flex;
  }
   @media (max-width: 768px) {
    display: none;
    .filter__cont {
    display: none;
  }
  }

`
export const Item = styled.li<any>`
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  z-index: 100000;
  display: flex;
  align-items: center;
  position: relative;
  color: #2d2c2c;
  overflow: visible;
   h6 {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      font-weight: 700;
  }
   #cheveron {
        width: 11px;
        height: 11px;
        svg {
          stroke: #2d2c2c;
        }
     }
  &:hover {
    color: #012009;
  }
`;
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding: 0px;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 0px;
  }
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   width: 60px;
   right: 50px;
   position: relative;
  cursor: pointer;
  p {
    margin-bottom: 0px;
    font-size: 7px;
    text-align: center;
    font-weight: 600;
  }
   .icon {
        width: 14px;
        height: 14px;
        stroke: grey;
        margin-left: 0px;
        margin-bottom: 4px;
        span {
          display: inline-block;
          position: absolute;
          .icon {
            width: 10px;
            height: 10px;
            right: -140%;
            top: 2px;
          }
        }
    }
    .icon:last-child {
       width: 12px;
        height: 12px;
    }
`
export const HeaderMenuCont = styled.div`
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: end;
    overflow: visible;
    #cart {
      width: 14px;
      height: 14px;
    }
    #bag {
      width: 16px;
      height: 16px;
      position: relative;
      top:  1px;
      margin-right: 20px;
      cursor: pointer;
      svg {
        fill: #242424;
      }
    }
    @media(max-width: 768px) {
       #bag {
          margin-right: 0px;
       }
    }
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 45px;
  /* padding: 0px 20px; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  a {
      text-decoration: none;
      color: inherit;
    h6 {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
  }
}
`;
export const CartItemsCounter = styled.div<any>`
  height: 20px;
  min-width: 20px;
  padding: 0px 2px;
  color: ${props => props.theme.dark.colors.labels.pri
  };
  border-radius: 50%;
  position: absolute;
  top:-8px;
  font-size: 11px;
  border:none;
  right:-12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  z-index: 50;
  
  
`
export const CatCont = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px) {
      .divider {
        display: none;
      }
    }
`
export const IconsGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0px;
  height: 100%;
  .icon {
        width: 18px;
        height: 18px;
        svg {
          stroke: #161616;
        }
        
        margin-right: 0px;
    }
  #cheveron {
      width: 11px;
      height: 11px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  
`

export const Container = styled.div<any>`
  background-color: inherit;
  height: 32px;
  width: 100%;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: 13px;
  padding: 0px;
   label {
    font-size: 11px;
    width: auto;
    left: 0px;
    font-size: 11px;
    color: grey;
    margin-bottom: 0px;
    pointer-events: none;
    position: relative;
    background-color: transparent;
   
  }
  div {
    height: 20px;
  }
`

export const Header = styled.header`
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 22;
    padding-top: 20px;
    justify-content: space-between;
    @media(max-width: 768px) {
      padding: 20px 10px 0px 10px;
    }
`
export const ListCont = styled.ul<any>`
  top: 5px;
  width: 100px;
  margin: auto;
  left: 0%;
  padding-left: 0px;
  border-radius: 6px;
  z-index: 41000;
  position: absolute;
  background-color:white;
  overflow-y: auto;
  li:first-child {
    border-radius: 6px 6px 0px 0px;
  }
  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
  ::-webkit-scrollbar {
    width: 0 !important
  }
  border: 1px solid rgba(71, 75, 76, 0.055);
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.12) 0 15px 30px 0px;
`
export const ListItem = styled.li<any>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  height: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  background-color:white;
  :hover {
    background: whitesmoke;
    p {
      color: black;
    }
  }
  p{
    width: 100%;
    margin-bottom: 0px;
    font-size: 10px;
    padding-left: 10px;
    color: ${props => props.active ? '#25ec78' :'#707070'} ;
    text-align: left;
    font-weight: 700;
  }
`
export const TabLinksCont = styled.div`
width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;

`
export const TabLinks = styled.ul`
list-style: none;
padding: 0px;
height:100%;
display: flex;
align-items: center;
justify-content: center;
margin: 0px;
border-radius: 6px;
/* background: whitesmoke; */

`

export const TabLink = styled.li<any>`
 height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  a {
    text-decoration: none;
    font-size: 10.5px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    text-transform: uppercase;
    color: ${props => props.active ? 'black' : '#989797'};
    &:hover {
      color: #012009;
    }
    ::before {
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    background: ${props => props.active ? 'black' : 'transparent'};
  }
  }
`
export const ImageCont = styled.div<any>`
    background: rgb(230 230 230);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border: 1px solid whitesmoke;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
     div {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      font-weight: 500;
      background: ${props => props.bg};
  }
`
export const HeadItemsCont = styled.div`
    width: 100%;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 30% 1fr;
    position: relative;
    align-items: center;
    justify-content: space-between;
    .page__title {
      margin-bottom:0px;
      font-weight: 600;
      font-size: 22px;
      width: 100%;
      text-align: center;
      color: #25ec78;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #25ec78;
      margin-bottom: 0px;
    }
    @media(max-width: 768px) {
      .home {
        display: none;
      }
      .page__title {
        display: block;
      }
    }
`